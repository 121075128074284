import React, { useEffect } from 'react'
import DrTab from './DrTab'
import {useDispatch} from 'react-redux'
import { GetAllDoctorLists } from '../../Thunk/doctorThunks';
import { Link } from 'react-router-dom';
export default function SpecialistDrTab() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllDoctorLists())
  },[])
  return (
    <>
    <section className='py-12 px-4'>
        <div className='container mx-auto flex items-center justify-between mb-16'>
            <h2 className='font-bold lg:text-4xl pagetitle relative text-xl'> Specialist Doctors</h2>
            <div>
                <Link to="/find-doctor" state={{name:"All"}} className='inline-block border-2 rounded-full md:px-5 md:py-3 border-primary text-primary font-bold hover:bg-blue-700 hover:text-white text-center p-2 md:text-base text-xs'>See All Doctor</Link>
            </div>
        </div>
        <div className='container mx-auto '>
            <DrTab/>
        </div>
    </section>
    </>
  )
}
