import { FETCH_ALL_FAQS} from './types';

export  const fetchAllFaqs = (payLoadData) => ({
  type: FETCH_ALL_FAQS, 
  payload: payLoadData,
});




