import { Grid } from '@mui/material'
import React from 'react'

export default function DownloadApp() {
  return (
    <>
    <section className='py-14'>
        <div className='container mx-auto px-4'>
            <Grid container columns={12} spacing={4} className='items-center'>
                <Grid item lg={6} md={6} sm={12}>
                    <img src='assets/images/mobileImg.png' className='mx-auto h-96'/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} className='lg:pr-32'>
                    <h4 className='text-lg font-bold mb-4'>Working for Your Better Health.</h4>
                    <h2 className='lg:text-4xl font-bold mb-6'>Download the Doccure App today!</h2>
                    <p className='text-base mb-9'>Consumers prefer a single unified experience for mental and physical health*. And they appreciate the convenient access and personalized support they get from our integrated app.</p>
                    <div>
                        <a href='#' className='border-2 border-blue-700 bg-primary text-white px-7 py-3 rounded-full transition-all duration-300 hover:bg-blue-900 inline-block'>Request for demo</a>
                    </div>
                </Grid>
            </Grid>
        </div>
    </section>
    </>
  )
}
