import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

const ShareDesign = ({isOpen,onClose,link}) => {
  const copyToClipboard = () => {
    const copyLink = link;
    navigator.clipboard.writeText(copyLink);
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg lg:w-[800px] md:w-[600px] w-[95%]">
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            padding: 20,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>
              Share this blog
            </h2>
            <button
              style={{ border: "none", background: "none", cursor: "pointer" }}
              onClick={onClose}
            >
              <span style={{ fontSize: 20 }}>&times;</span>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 20,
            }}
          >
            <FacebookShareButton url="https://www.facebook.com/sharer/sharer.php"><FacebookIcon size={32} round={true} /></FacebookShareButton>
            <TelegramShareButton url="https://telegram.me/"><TelegramIcon size={32} round={true} /></TelegramShareButton>
            <TwitterShareButton url="https://twitter.com/intent/tweet"><XIcon size={32} round={true} /></TwitterShareButton>
            <WhatsappShareButton url="https://web.whatsapp.com/"><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
          </div>
          <div style={{ marginTop: 20 }}>
            <p style={{ margin: 0, fontSize: 14, color: "#555" }}>
              Or copy link
            </p>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 8 }}
            >
              <input
                type="text"
                value={link}
                readOnly
                style={{
                  flex: 1,
                  padding: 8,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  fontSize: 14,
                }}
              />
              <button
                onClick={copyToClipboard}
                style={{
                  marginLeft: 8,
                  padding: "8px 16px",
                  backgroundColor: "#0000ff",
                  color: "white",
                  border: "none",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareDesign;
