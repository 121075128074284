import Cookies from 'js-cookie';
export const GetUserLocality = () => {
  const location = Cookies.get('userLocality');
  return location 
}

export const GetUserCity = () => {
  const location = Cookies.get('userCity');
  return location 
}
