const ParentsSection = ({register, disabledFields}) => {
    return (
        <>
        <label className="text-sm mt-2 mb-1 block">Who is alive</label>

        <div class="flex items-center me-4">
          <input
            type="radio"
            value="both"
            {...register("who_is_alive")}
            disabled={disabledFields}
          />
          <label for="inline-radio" class="ms-2 text-sm font-medium">
            both
          </label>
        </div>
        <div class="flex items-center me-4">
          <input
            type="radio"
            value="only father"
            {...register("who_is_alive")}
            disabled={disabledFields}
          />
          <label for="inline-2-radio" class="ms-2 text-sm font-medium">
            only father
          </label>
        </div>
        <div class="flex items-center me-4">
          <input
            type="radio"
            value="only mother"
            {...register("who_is_alive")}
            disabled={disabledFields}
          />
          <label for="inline-2-radio" class="ms-2 text-sm font-medium">
            only mother
          </label>
        </div>
      </>
    );
}
export default ParentsSection;