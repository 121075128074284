import { Grid } from '@mui/material'
import React from 'react'

export default function Blogcard() {
  return (
    <>
    <Grid container columns={12} spacing={4} className='justify-center'>
        <Grid item lg={4}>
            <div class="p-4 drCard">
                <a href="#" class="drcardImg block">
                    <img src="assets/images/dr1.png"/>
                </a>
                <div className='flex gap-4 mb-3 mt-4'>
                    <div>
                        <a href='#' className='text-black'><i class="fa-regular fa-user text-primary"></i> Admin</a>
                    </div>
                    <div>
                        <a href='#'><i class="fa-regular fa-calendar text-primary"></i> February 10, 2024</a>
                    </div>
                </div>
                <h2><a href='#' className='text-black text-lg font-bold'>Maximizing Family Well-being: Telehealth Strategies</a></h2>
            </div>
        </Grid>
        <Grid item lg={4}>
            <div class="p-4 drCard">
                <a href="#" class="drcardImg block">
                    <img src="assets/images/dr1.png"/>
                </a>
                <div className='flex gap-4 mb-3 mt-4'>
                    <div>
                        <a href='#' className='text-black'><i class="fa-regular fa-user text-primary"></i> Admin</a>
                    </div>
                    <div>
                        <a href='#'><i class="fa-regular fa-calendar text-primary"></i> February 10, 2024</a>
                    </div>
                </div>
                <h2><a href='#' className='text-black text-lg font-bold'>Maximizing Family Well-being: Telehealth Strategies</a></h2>
            </div>
        </Grid>
        <Grid item lg={4}>
            <div class="p-4 drCard">
                <a href="#" class="drcardImg block">
                    <img src="assets/images/dr1.png"/>
                </a>
                <div className='flex gap-4 mb-3 mt-4'>
                    <div>
                        <a href='#' className='text-black'><i class="fa-regular fa-user text-primary"></i> Admin</a>
                    </div>
                    <div>
                        <a href='#'><i class="fa-regular fa-calendar text-primary"></i> February 10, 2024</a>
                    </div>
                </div>
                <h2><a href='#' className='text-black text-lg font-bold'>Maximizing Family Well-being: Telehealth Strategies</a></h2>
            </div>
        </Grid>
    </Grid>
    </>
  )
}
