import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calender.css";
import { parseApiData } from "../../Utils/ParseCalendarData";
import moment from "moment";
import { DisableCalendarTile } from "../../Utils/disableCalendarTile";

const Bookingcalender = ({
  data,
  date,
  setDate,
  setSelectedSlot,
  selectedSlot,
  disabled,
}) => {
  const [message, setMessage] = useState("");
  const [slots, setSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setAvailableDates(parseApiData(data));
    }
  }, [data]);

  const isAvailable = (date) => {
    return availableDates.some(
      (availableDate) =>
        availableDate.date.toDateString() === date.toDateString()
    );
  };

  const getAvailableSlots = (date) => {
    const availableDate = availableDates.find(
      (availableDate) =>
        availableDate.date.toDateString() === date.toDateString()
    );
    return availableDate ? availableDate.slots : [];
  };

  const isSlotOver = (date) => {
   return availableDates.some(
        (availableDate) =>
          moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
          DisableCalendarTile(availableDate.slots)
      );

  };


  const tileClassName = ({ date, view }) => {
    
    if (view === "month") {
      return isAvailable(date) && !isSlotOver(date)
        ? "available-slot"
        : "disable-slot";
    }
    return null;
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return date < now;
    }
    return false;
  };

  const handleDateChange = (date) => {
    setDate(date);
    if (!isAvailable(date)) {
      setMessage("No slots available");
      setSlots([]);
      setSelectedSlot("");
    } else if (isSlotOver(date) === true) {
      setMessage("All Slots are over");
      setSlots([]);
      setSelectedSlot("");
    } else {
      setMessage("");
      setSlots(getAvailableSlots(date));
      setSelectedSlot("");
    }
  };

  return (
    <>
      <div className="p-5 rounded-xl shadow-lg bg-white mb-6">
        <Calendar
          onChange={handleDateChange}
          value={date}
          tileClassName={tileClassName}
          tileDisabled={tileDisabled}
        />
        {message && (
          <p className="bg-red-200 text-red-700 py-2 px-4 rounded-lg mt-3 font-semibold">
            {message}
          </p>
        )}
      </div>
      <div className="p-5 rounded-xl shadow-lg bg-white mb-3">
        <p className="mt-0">Selected Date: {date.toDateString()}</p>
        {slots.length > 0 && (
          <div>
            <h3 className="font-semibold my-3">Select Slot:</h3>
            <div className="flex gap-2 flex-wrap">
              {slots.map((slot, index) => (
                <div key={index} onClick={() => setSelectedSlot(slot)}>
                  <label>
                    <input type="radio" name="slot" className="hidden" />
                    <span
                      className={`inline-block text-sm py-2 px-4 border rounded border-gray-400 text-gray-500 min-w-32 text-center cursor-pointer ${
                        disabled === false && selectedSlot === slot
                          ? "border-blue-600 text-blue-600 bg-[#dcdcff]"
                          : disabled === true && selectedSlot === slot
                          ? "border-[#ff0000] text-[#ff0000] bg-[#ffdcdc]"
                          : null
                      }
                    }`}
                    >
                      {slot}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Bookingcalender;
