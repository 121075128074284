import { Grid } from '@mui/material'
import React from 'react'

export default function DownloadApp() {
  return (
    <>
    <section className='py-12 px-4'>
        <div className='container mx-auto'>
            <div className='bg-primary relative downloadappBox' style={{ backgroundImage: 'url(../assets/images/bg-download.png)', }}>
                <Grid container columns={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={8} md={8} lg={5}>
                        <h3 className='mb-5 text-white font-semibold  md:text-xl sm:text-md lg:text-2xl'>Working for Your Better Health.</h3>
                        <h2 className='font-bold text-white mb-12  md:text-xl'>Download the CallSalute App today!</h2>
                        <div className='flex gap-4 appLogo'>
                            <a href='#'><img src='assets/images/google-play.png'/></a>
                            <a href='#'><img src='assets/images/appstore.png'/></a>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={4} lg={3}>
                        <img src='assets/images/mobileImg.png' className='w-full mobileCover'/>
                    </Grid>
                </Grid>
                        
            </div>
        </div>
    </section>
    </>
  )
}
