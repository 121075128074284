import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookClinicAppointment } from "../../Thunk/patientThunks";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { clearError, clearMsg } from "../../Actions/Patient/userActions";

export default function CheckoutTable({ data }) {
  const [discount, setDiscount] = useState("");
  const { user } = useSelector((state) => state.auth);
  const {msg,error} = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TotalCalculator = () => {
    return data.appointment_type === "Clinic"
      ? data.Fares.clinic_fare - parseInt(discount)
      : data.Fares.online_fare;
  };
  useEffect(() => {
    setDiscount(data.appointment_type === "Clinic" ? "1:00" : "0:00");
  }, []);
  
  useEffect(() => {
    if(msg) {
        toast.success(msg);
        setTimeout(() => {
            dispatch(clearMsg())
          navigate('/dashboard')
        },1500)
    }
    if(error) {
      toast.error(error);
      setTimeout(() => {
        dispatch(clearError())
      navigate('/')
    },1500)
    }
  }, [msg,error]);

  const onBookingHandler = () => {
    dispatch(
      BookClinicAppointment({
        Id: user.PatientId,
        drId: data.DoctorId,
        dr_name: data.Name,
        dr_speciality:data.ProfessionalInformation.Specializations.join(' '),
        dr_pic: data.profilePic,
        patient_pic: user.profilePic,
        patient_gender:user.gender,
        appointment_type: data.appointment_type,
        appointment_date: data.appointment_date,
        appointment_time: data.appointment_time,
        appointment_fees: "$" + TotalCalculator(),
        clinic_address: data.appointment_address,
      })
    );
  };
  return (
    <>
      <div className="rounded-xl shadow-lg bg-white overflow-hidden border p-5">
        <table className="table-auto w-full mb-4">
          <tbody>
            <tr className="border-b">
              <td className="px-1 py-3">Consult Fee</td>
              <td className="px-1 py-3 font-semibold text-right">
                ${" "}
                {data.appointment_type === "Clinic"
                  ? data.Fares.clinic_fare
                  : data.Fares.online_fare}
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-1 py-3">Discount</td>
              <td className="px-1 py-3 font-semibold text-right">
                $ {discount}
              </td>
            </tr>
            <tr className="border-b-2 border-gray-500 border-dotted">
              <td className="px-1 py-3">Offer for you</td>
              <td className="px-1 py-3 font-semibold text-right">
                $ {discount}
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-1 py-3 font-bold text-primary">Grand Total</td>
              <td className="px-1 py-3 font-bold text-right text-primary">
                $ {TotalCalculator()}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-sm text-gray-500">
          By booking Appointment, you are agreeing to Terms and Conditions of
          the usage of 24x7,Privacy Policy, Refund Policy
        </p>
        <div className="text-right mt-4">
          <button
            type="button"
            onClick={onBookingHandler}
            className="py-2 inline-block text-center px-4 text-white bg-primary rounded-full min-w-36"
          >
            {data.appointment_type === "Clinic"
              ? "PROCEED"
              : `PAY  ${"$" + data.Fares.online_fare}`}
          </button>
        </div>
      </div>
    </>
  );
}
