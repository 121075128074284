import React, { useState,useEffect } from "react";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../Thunk/authThunks";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
export default function Register() {
  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;
  const navigate = useNavigate();
  const {error, msg} = useSelector((state) => state.auth); 
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    if (error) {
      toast.error(error)
    }
    if (msg === "User registered successfully") {
      navigate("/signin");
    }
  },[error,msg]);
  const handleRegister = (data) => {
     dispatch(registerUser(data));
  };
  return (
    <>
      <section className="py-20 border-t-2 bg-slate-50">
        <div className="container mx-auto px-4">
          <form onSubmit={handleSubmit(handleRegister)}>
            <Grid container columns={12} className="justify-center">
              <Grid item xs={12} md={8} lg={6} xl={5}>
                <div className="p-9 bg-white formsignin shadow-lg">
                  <h2 className="lg:text-3xl md:text-2xl text-2xl font-bold mb-6">
                    Create your account
                  </h2>
                  <div className="mb-4">
                    <label>Sign up as a *</label>
                    <div className="mt-2">
                      <select
                        {...register("userType", {
                          required: true,
                        })}
                        className={`border-2 border-black w-full py-4 px-4 rounded-lg text-sm ${
                            errors.userType ? "border-red-500" : "border-black"
                          }`}
                      >
                        <option>Select</option>
                        <option>Patient</option>
                        <option>Doctor</option>
                      </select>
                      {errors.userType && (
                        <p className="text-red-500 text-xs mt-1">
                          userType is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label>Name *</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className={`border-2 border-black w-full py-4 px-4 rounded-lg text-sm ${
                          errors.firstName ? "border-red-500" : "border-black"
                        }`}
                        placeholder="Enter your Name"
                        {...register("Name", {
                          required: "Name is required",
                        })}
                      />
                      {errors.Name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Name.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label>Gender</label>
                    <div className="mt-2">
                      <select
                        {...register("Gender", {
                          required: true,
                        })}
                        className={`border-2 border-black w-full py-4 px-4 rounded-lg text-sm ${
                            errors.Gender ? "border-red-500" : "border-black"
                          }`}
                      >
                        <option>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                      {errors.Gender && (
                        <p className="text-red-500 text-xs mt-1">
                          gender is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label>Email</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className={`border-2 border-black w-full py-4 px-4 rounded-lg text-sm ${
                          errors.email ? "border-red-500" : "border-black"
                        }`}
                        placeholder="Email Address"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[a-z0-9._-]+@[a-z]+\.[a-z]{2,3}$/,
                            message: "Invalid email format",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label>Password</label>
                    <div className="mt-2 relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`border-2 border-black w-full py-4 px-4 rounded-lg text-sm ${
                          errors.password ? "border-red-500" : "border-black"
                        }`}
                        placeholder="Enter Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          maxLength: {
                            value: 15,
                            message: "Password must not exceed 15 characters",
                          },
                        })}
                      />
                      {errors.password && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.password.message}
                        </p>
                      )}
                      <button
                        type="button"
                        className="absolute right-3 top-4"
                        onClick={togglePasswordVisibility}
                      >
                        {" "}
                        {showPassword ? (
                          <i class="fa-solid fa-eye-slash"></i>
                        ) : (
                          <i class="fa-solid fa-eye"></i>
                        )}{" "}
                      </button>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button className="w-full bg-primary rounded-lg text-white font-semibold py-4 px-4 hover:bg-black transition-all delay-200">
                      Register
                    </button>
                  </div>
                  <div className="text-center">
                    <p>
                      Already have an account?{" "}
                      <a href="/signin" className="font-semibold text-primary">
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </section>
    </>
  );
}
