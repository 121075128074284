import { useSelector } from "react-redux";

const ChildrenSection = ({register, children_gender, are_you_pregnant, disabledFields }) => {
    const {user} = useSelector(state => state.auth)
  return (
    <>
      <label className="text-sm mt-2 mb-1 block">Child Gender</label>
      <select
        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
        {...register("children_gender")}
        disabled={disabledFields}
      >
        <option>Select</option>
        <option>Male</option>
        <option>Female</option>
      </select>

      {children_gender == "Female" && (
        <>
          <label className="text-sm mt-2 mb-1 block">Type of delivery</label>
          <select
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("type_of_delivery")}
            disabled={disabledFields}
          >
            <option>Select</option>
            <option>Normal Delivery</option>
            <option>C-Section Delivery</option>
          </select>

          <label className="text-xs mt-2 mb-1 block">
            Any Pregnancy Complications
          </label>
          <input
            type="text"
            {...register("pregnancy_complications")}
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            disabled={disabledFields}
          />
        </>
      )}
      {user.gender == "Female" && (
        <>
          <label className="text-sm mt-2 mb-1 block">Are you pregnant</label>
          <div class="flex">
            <div class="flex items">
              <input
                type="radio"
                value="yes"
                {...register("are_you_pregnant")}
                disabled={disabledFields}
              />
              <label for="inline-radio" class="ms-2 text-sm font-medium">
                yes
              </label>
            </div>
            <div class="flex items">
              <input
                type="radio"
                value="no"
                {...register("are_you_pregnant")}
                disabled={disabledFields}
              />
              <label for="inline-radio" class="ms-2 text-sm font-medium">
                no
              </label>
            </div>
          </div>
          {are_you_pregnant == "yes" && (
            <>
              <label className="text-sm mt-2 mb-1 block">
                Week of gestation
              </label>
              <input
                type="number"
                disabled={disabledFields}
                {...register("week_of_gestation")}
                className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default ChildrenSection;
