import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({ children }) => {
  const { user, token } = useSelector((state) => state.auth);
  return user === null && token === null ? (
    children
  ) : user && token && user.userType === "Patient" ? (
    <Navigate to="/dashboard" replace={true} />
  ) : (
    <Navigate to="/dr-dashboard" replace={true} />
  );
};
