import React, { useState } from "react";

export const ClinicDropdown = ({ data, selectedClinic, setSelectedClinic }) => {
  const [show, setShow] = useState(false);

  const handleItemClick = (item) => {
    setSelectedClinic(item);
    setShow(false);
  };

  return (
    <div className="dropdown relative inline-flex w-[100%] mb-3">
      <button
        type="button"
        onClick={() => setShow(!show)}
        className="inline-flex w-full justify-center items-center gap-2 py-3 px-6 text-md bg-white rounded-lg cursor-pointer font-semibold text-center shadow-md transition-all duration-500"
      >
        {selectedClinic.clinic_name || selectedClinic}
        <svg
          className="dropdown-open:rotate-180 w-2.5 h-2.5"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          ></path>
        </svg>
      </button>
      {show && (
        <div className="rounded-xl shadow-lg p-3 bg-white absolute top-full  w-full mt-2 divide-y divide-gray-200">
          <ul className="py-2">
            {data.map((itx, index) => (
              <li
                key={index}
                className="hover:bg-slate-300 cursor-pointer"
                onClick={() => handleItemClick(itx)}
              >
                {itx.clinic_name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
