import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ClinicDropdown } from "../Custom/dropdown";
import toast from "react-hot-toast";
export default function Clinicinfo({ data, date, slot }) {
  const navigate = useNavigate();
  const [selectedClinic, setSelectedClinic] = useState("Select Clinic");
  const onClinicBookingHandler = () => {
    data.appointment_type = "Clinic";
    data.appointment_date = moment(date).format("DD MMM YYYY");
    data.appointment_time = slot;
    data.appointment_address = selectedClinic.clinic_address;
    if (selectedClinic.clinic_address == undefined) {
      toast.error("Please Select a Clinic !");
    } else {
      navigate("/checkout", { state: data });
    }
  };
  return (
    <>
      <div className="rounded-xl shadow-lg bg-white overflow-hidden">
        <div className="py-3 px-5 flex justify-between bg-violet-200">
          <h3 className="font-semibold text-lg">Clinic Appointment</h3>
          <h4 className="text-primary font-semibold">
            $ {data.Fares.clinic_fare} fee
          </h4>
        </div>
        <div className="py-4 px-5">
          <div className="flex gap-3 mb-2">
            <span>
              <i class="fa-solid fa-user-doctor text-cyan-400"></i>
            </span>
            <p>{data.Name}</p>
          </div>
          <div className="flex gap-3 mb-2">
            <span>
              <i class="fa-regular fa-calendar-days text-green-700"></i>
            </span>
            <p>
              {moment(date).format("DD MMM YYYY")}, {slot}
            </p>
          </div>
          {selectedClinic.clinic_name && (
            <div className="flex gap-3 mb-2">
              <span>
                <i class="fa-sharp fa-solid fa-location-dot text-red-900"></i>
              </span>
              <p>{selectedClinic.clinic_address?.substring(0, 33) + ".."}</p>
            </div>
          )}
          <ClinicDropdown
            data={data.Clinics}
            selectedClinic={selectedClinic}
            setSelectedClinic={setSelectedClinic}
          />
          <div className="mt-1">
            <button
              type="button"
              onClick={onClinicBookingHandler}
              className="py-2 block text-center px-4 text-white bg-primary rounded-full  min-w-36"
            >
              Book Clinic Appointment
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
