import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Memberslide() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow:7,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000, 
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow:3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };
  return (
    <>
        
        <div className='awardSlide slidermember  px-3'>
        <Slider {...settings}>
        <div className='awardLogo'>
            <img src='assets/images/member1.png' alt="member1pic"/>
        </div>
        <div className='awardLogo'>
            <img src='assets/images/member2.png' alt="member2pic"/>
        </div>
        <div className='awardLogo'>
            <img src='assets/images/member3.png' alt="member3pic"/>
        </div>
        <div className='awardLogo'>
            <img src='assets/images/member4.png' alt="member4pic"/>
        </div>
        <div className='awardLogo'>
            <img src='assets/images/member5.png' alt="member5pic"/>
        </div>
        <div className='awardLogo'>
            <img src='assets/images/member6.png' alt="member6pic"/>
        </div>
        <div className='awardLogo'>
            <img src='assets/images/member7.png' alt="member7pic"/>
        </div>
        <div className='awardLogo '>
            <img src='assets/images/member5.png' alt="member8pic"/>
        </div>
        </Slider>
    </div>
                
            
    </>
  )
}
