import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { BlogCategory } from "../../Utils/StaticData";
import React, { useEffect, useState } from "react";
import ToggleNavs from "../ToggleNavs/toggleNavs";
import BannerDashboard from "../Dashboard/BannerDashboard";
import { useDispatch, useSelector } from "react-redux";
import { CreateBlogPosts } from "../../Thunk/blogThunks";
import toast from "react-hot-toast";
import { clearMsg } from "../../Actions/Blogs/blogAction";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants } from "../../Constants";
export const AddBlog = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { state } = location;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    values: {
      blogCategory: state?.blogCategory || '',
      blogTitle: state?.blogTitle || '',
    },
  });
  const [textEditor, setTextEditor] = useState(state?.blogContent || "");
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [showContentError, setShowContentError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { msg } = useSelector((state) => state.blog);
  const dispatch = useDispatch();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };
  const handleBlogCreation = (data) => {
    if (data.blogCategory === "Select Blog Category") {
      setShowCategoryError(true);
    }
    if (textEditor == "") {
      setShowContentError(true);
    } else {
      const formData = new FormData();
      formData.append("blogId", state?._id);
      if (data.blogImage[0] === undefined) {
        formData.append("blogImage", state.blogImage);
      } else {
        formData.append("blogImage", data.blogImage[0]);
      }
      formData.append("blogTitle", data.blogTitle);
      formData.append("blogContent", textEditor);
      formData.append("blogCategory", data.blogCategory);
      formData.append("blogPostDate", new Date());
      formData.append(
        "blogPostedBy",
        JSON.stringify([{ postedBy: user.Name, userId: user.Id }])
      );
      formData.append("isUpdated", true);
      dispatch(CreateBlogPosts(formData));
    }
  };

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      setTimeout(() => {
        navigation('/blog-list')
        dispatch(clearMsg());
      }, 1200);
    }
  }, [msg]);

  useEffect(() => {
    if (textEditor !== "") {
      setShowContentError(false);
    }
    if (watch("blogCategory") !== "Select Blog Category") {
      setShowCategoryError(false);
    }
  }, [watch("blogCategory"), textEditor]);
  return (
    <>
      <BannerDashboard pageTitle="Blog" />
      <section className="py-8">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <div className="md:p-6 md:shadow-lg md:border lg:rounded-lg">
                <form onSubmit={handleSubmit(handleBlogCreation)}>
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <label className="text-sm mb-2 block">
                        Blog Title <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("blogTitle", {
                          required: "Blog Title is required",
                        })}
                        placeholder="Enter Blog Title"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.blogTitle && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.blogTitle.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <label className="text-sm mb-2 block">
                        Blog Image <span className="text-red-600">*</span>
                      </label>
                      {state?.blogImage && (
                        <>
                          <label className="text-sm mb-2 block">
                            Previous Image
                          </label>
                          <img
                            src={`${Constants.getImg}${state?.blogImage}`}
                            className="w-[50px] h-[50px] rounded-md mb-3"
                          />
                        </>
                      )}
                      <div class="font-[sans-serif] w-full mx-auto">
                        <input
                          type="file"
                          {...register("blogImage", {
                            validate: (value) => {
                              if (state?.blogImage || value.length > 0) {
                                return true; // No validation error if there's an existing image or a new file is provided
                              }
                              return "Please add a blog image!";
                            },
                          })}
                          class="w-full text-gray-400 font-semibold text-sm border-slate-300 bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-3 file:px-2 file:mr-4 file:bg-gray-200 file:hover:bg-gray-300 file:text-gray-500 rounded-md"
                        />
                      </div>
                      {errors.blogImage && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.blogImage.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <label className="text-sm mb-2 block">
                        Blog Content <span className="text-red-600">*</span>
                      </label>
                      <ReactQuill
                        theme="snow"
                        name="blogContent"
                        value={textEditor}
                        onChange={setTextEditor}
                        placeholder={"Write something awesome..."}
                        required
                        modules={modules}
                      />
                      {showContentError == true && (
                        <p className="text-red-500 text-xs mt-1">
                          Blog Content Needed !
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <label className="text-sm mb-2 block">
                        Blog Category <span className="text-red-600">*</span>
                      </label>
                      <select
                        {...register("blogCategory")}
                        class="py-2 px-3 pe-9 block w-full border border-gray-400 rounded-lg text-sm "
                      >
                        <option selected>Select Blog Category</option>
                        {BlogCategory.map((itx) => (
                          <option>{itx}</option>
                        ))}
                      </select>
                      {showCategoryError == true && (
                        <p className="text-red-500 text-xs mt-1">
                          Select Blog Category !
                        </p>
                      )}
                    </Grid>
                  </Grid>

                  <div className="mt-3 text-right">
                    <button className=" bg-primary rounded-lg text-white font-semibold py-3 px-8 hover:bg-black transition-all delay-200 text-sm">
                      {state?.isUpdated === true
                        ? "Update Blog Post"
                        : "Create Blog Post"}
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};
