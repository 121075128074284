import React from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeletePatientMedicalRecord } from "../../../Thunk/patientThunks";
const ListMedicalRecord = ({ recordItem}) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const handleDeleteRecords = () => {
    dispatch(DeletePatientMedicalRecord({patientId:user.PatientId, recordId:recordItem._id}))
}
  return (
    <div className="flex flex-col  bg-gray-100">
      <div className="flex-grow overflow-auto p-2">
        <div
          key={recordItem._id}
          className="bg-white rounded-lg p-4 mb-2 shadow"
        >
          <div className="flex items-center justify-between ">
            <div>
              <h2 className="font-semibold">{recordItem.Title}</h2>
            </div>
            <div className="flex">
              <button type="button" onClick={() => navigation("/add-medical-record",{state:recordItem})}><EditIcon  fontSize="small" color="primary"/></button>
              <button type="button" onClick={handleDeleteRecords}><DeleteIcon fontSize="small" color="error" /></button>
            </div>
          </div>
          <div>
            <h2 >{recordItem.ShortDescription}</h2>
          </div>
          <div className="flex items-center text-sm text-gray-500">
            <span className="mr-4">{recordItem.DateOfRecord}</span>
          </div>
          <div className="flex items-center text-sm text-gray-500">
            <PictureAsPdfIcon />
            <span className="ms-1">{recordItem.Attachment.substring(13,recordItem.Attachment.length)}</span>
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default ListMedicalRecord;
