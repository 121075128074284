import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Constants } from "../../Constants";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  AddBlogComment,
  FetchBlogPostsByBlogId,
  LikeBlog,
  UnlikeBlog,
} from "../../Thunk/blogThunks";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { clearMsg } from "../../Actions/Blogs/blogAction";
import ShareDesign from "../ShareModal/shareModal";
export default function Blogdetails() {
  const navigation = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { msg,blogPerUser} = useSelector((state) => state.blog);
  const { token, user } = useSelector((state) => state.auth);
  const [handleOpen, setHandleOpen] = useState(false);
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();
  useEffect(() => {
    dispatch(FetchBlogPostsByBlogId(id));
  }, [id, msg]);

  const handleBlogComment = (data) => {
    const responseBody = {
      blogId: id,
      blogCommentData: {
        ...data,
        commentPostedOn: new Date(),
      },
    };
    dispatch(AddBlogComment(responseBody));
  };
  const likeToggle = () => {
    if (blogPerUser?.blogLikes?.length > 0) {
      const isPresent = blogPerUser?.blogLikes.some((itx) => itx === user?.Id);
      return isPresent;
    } else {
      return false
    }
  };

  const handleBlogLike = () => {
    if (!token && !user) {
      toast.error("Sign In is required !");
      setTimeout(() => {
        navigation("/signin");
      }, 1200);
    } else {
    if (likeToggle()) {
      dispatch(UnlikeBlog({blogId: id, likedById: user.Id}))
    } else {
      dispatch(LikeBlog({ blogId: id, likedById: user.Id }));
    }
  }
  };

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      setTimeout(() => {
        dispatch(clearMsg());
        reset();
      }, 1200);
    }
  }, [msg]);


  return (
    <>
      <section className="border-t-2 bg-[#F6F6F6]">
        <div className="container mx-auto px-4 py-8">
          <div className="md:flex gap-3 items-center justify-center">
            <div className="mb-5 md:mb-0">
              <h1 className="text-3xl text-center font-bold">Blog</h1>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto px-4 py-10">
          <Grid container spacing={4} className="justify-center">
            <Grid item xl={7} lg={8}>
              <div className="mb-4">
                <img
                  src={`${Constants.getImg}${blogPerUser?.blogImage}`}
                  className="aspect-[5/3] object-cover rounded-[20px]"
                />
              </div>
              <div className="pb-6">
                <div className="md:flex gap-3 items-center justify-between">
                  <div className="flex gap-3 items-center mb-3">
                    <div className="px-3 py-2 bg-primary text-sm text-white rounded-lg">
                      {moment(blogPerUser?.blogPostDate).format("DD MMM, YYYY")}
                    </div>
                    <div className="text-sm">
                      <i class="fa-solid fa-user text-primary"></i>
                      By{" "}
                      {blogPerUser &&
                      Array.isArray(blogPerUser.blogPostedBy) &&
                      blogPerUser.blogPostedBy.length > 0
                        ? blogPerUser.blogPostedBy[0]?.postedBy
                        : ""}
                    </div>
                    <div className="text-sm">
                      <i class="fa-solid fa-comments text-primary"></i> Comments
                      (
                      {blogPerUser?.blogComments
                        ? blogPerUser?.blogComments.length
                        : 0}
                      )
                    </div>
                  </div>
                  <div className="flex gap-5 mb-3">
                    <button
                      className="p-0 text-xl text-gray-400 hover:text-blue-600"
                      onClick={handleBlogLike}
                    >
                      {likeToggle() ? (
                        <i
                          class="fa-solid fa-heart"
                          style={{ color: "red" }}
                        ></i>
                      ) : (
                        <i class="fa-regular fa-heart"></i>
                      )}{" "}
                      {blogPerUser?.blogLikes &&
                        blogPerUser?.blogLikes.length > 0 &&
                        blogPerUser.blogLikes.length}
                    </button>
                    <button
                      type="button"
                      onClick={() => setHandleOpen(true)}
                      className="p-0 text-xl text-gray-400 hover:text-blue-600"
                    >
                      <i class="fa-regular fa-share-from-square"></i>
                    </button>
                  </div>
                </div>
                <h1 className="text-3xl font-bold mb-4">
                  {blogPerUser?.blogTitle}
                </h1>
                <div
                  className="text-base text-gray-700 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: blogPerUser?.blogContent,
                  }}
                ></div>
              </div>
              <div className="pt-6 border-t pb-5">
                <h2 className="text-2xl font-bold mb-5">
                  Comments (
                  {blogPerUser?.blogComments
                    ? blogPerUser?.blogComments.length
                    : 0}
                  )
                </h2>
                {blogPerUser?.blogComments &&
                blogPerUser?.blogComments.length > 0 ? (
                  blogPerUser.blogComments.map((itx) => (
                    <div className="flex gap-5 mb-5">
                      <div>
                        <img
                          src="../assets/images/user2.png"
                          className="w-12 min-w-12 h-12 object-cover rounded-full mt-1"
                        />
                      </div>
                      <div>
                        <h4 className="font-bold mb-1 text-lg">
                          {itx.commentedUserName}
                        </h4>
                        <p className="mb-2 text-sm text-gray-500">
                          {moment(itx.commentPostedOn).format("DD MMM YYYY")}
                        </p>
                        <p className="text-sm mb-2">{itx.commentMessage}</p>
                        <a href="#" className="my-2 text-sm text-gray-600">
                          <i class="fa-regular fa-reply"></i> Reply
                        </a>

                        {/* <div className="flex gap-5 mb-5 mt-5">
                        <div>
                          <img
                            src="/public/assets/images/user.jpg"
                            className="w-14 min-w-14 h-14 object-cover rounded-full mt-1"
                          />
                        </div>
                        <div>
                          <h4 className="font-bold mb-1 text-lg">
                            John F. Medina
                          </h4>
                          <p className="mb-2 text-sm text-gray-500">
                            25 Feb 2022
                          </p>
                          <p className="text-sm mb-2">
                            On the other hand, we denounce with righteous
                            indignation and dislike men who are beguiled and
                            demoralized by the charms of pleasure of the moment
                            so blinded
                          </p>
                          <a href="#" className="my-2 text-sm text-gray-600">
                            <i class="fa-regular fa-reply"></i> Reply
                          </a>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-lg font-bold text-center">
                    No Comments Yet
                  </p>
                )}
              </div>
              <div className="pt-4 bg-gray-100 rounded-lg p-6" id="comment-box">
                <form>
                  <h4 className="text-2xl font-bold mb-1">Leave a Comment</h4>
                  <p className="mb-5 text-sm text-gray-600">
                    Have any question? Ready to talk to us!
                  </p>

                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <input
                        type="text"
                        className="border-2 w-full py-4 px-4 rounded-lg text-sm border-black"
                        placeholder="Full Name"
                        {...register("commentedUserName", {
                          required: "Enter your full name",
                        })}
                      />
                      {errors.commentedUserName && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.commentedUserName.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <input
                        type="email"
                        className="border-2 w-full py-4 px-4 rounded-lg text-sm border-black"
                        placeholder="Email Address"
                        {...register("commentedUserMail", {
                          required: "Enter your email",
                          pattern: {
                            value: /^[a-z0-9._-]+@[a-z]+\.[a-z]{2,3}$/,
                            message: "Invalid email format",
                          },
                        })}
                      />
                      {errors.commentedUserMail && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.commentedUserMail.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <textarea
                        className="border-2 w-full py-4 px-4 rounded-lg text-sm border-black"
                        placeholder="Write Message"
                        {...register("commentMessage", {
                          required: "write some message...",
                        })}
                      ></textarea>
                      {errors.commentMessage && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.commentMessage.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <button
                        className="bg-primary rounded-lg  text-white font-semibold py-4 px-10 hover:bg-black transition-all delay-200"
                        onClick={handleSubmit(handleBlogComment)}
                      >
                        Submit Comment
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={12}>
              <div className="p-5 bg-gray-100 rounded-lg sticky top-10">
                <h3 className="text-2xl mb-6 font-bold border-b pb-4">
                  Recent Blog
                </h3>
                {/* <div className="flex mb-6 gap-4">
                  <div className="w-20 min-w-20">
                    <a href="">
                      <img
                        src="/assets/images/getin-bg.jpg"
                        className="w-20 h-16 rounded-lg"
                      />
                    </a>
                  </div>
                  <div>
                    <h2 className="text-base font-bold mb-1">
                      <Link>
                        Over ride the digital divide with additional
                      </Link>
                    </h2>
                    <p className="text-sm text-gray-500">
                      <i class="fa-light fa-calendar-days"></i> 20 Dec, 2024
                    </p>
                  </div>
                </div> */}

                <h3 className="text-center">No Recent Blogs Posted !</h3>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <ShareDesign
        isOpen={handleOpen}
        onClose={() => setHandleOpen(false)}
        link={`${process.env.REACT_APP_URL}/blog-details/${id}`}
      />
    </>
  );
}
