import React from "react";
import { Grid, Typography } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const PediatricalSection = ({
  register,
  fields,
  onAddHandler,
  onRemoveHandler,
  disableCondition,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">General Health Info</label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("GeneralhealthInfo")}
          disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Height</label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("PediatricPatientHeight")}
          disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Weight</label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("PediatricPatientWeight")}
          disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Developmental Milestones</label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("PadeiatricDevelopmentalMilestones")}
          disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Typography fontWeight="bold" sx={{ flexGrow: 1 }}>
          Vaccination Records
        </Typography>
        {!disableCondition && (
        <ControlPointIcon onClick={onAddHandler} sx={{ cursor: "pointer" }} />
        )}
      </Grid>

      {fields.map((record, index) => (
        <React.Fragment key={record.id}>
          <Grid item xs={6}>
            <label className="text-sm mb-2 block">Vaccine Name</label>
            <input
              {...register(
                `pediatricalVaccinationRecords.${index}.vaccineName`
              )}
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              disabled={disableCondition}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <label className="text-sm mb-2 block">Vaccination Date</label>
              </Grid>
              {index !== 0 && !disableCondition && (
                <Grid item xs={1.5}>
                  <RemoveCircleOutlineIcon
                    sx={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginBottom: "3px",
                    }}
                    onClick={() => onRemoveHandler(index)}
                  />
                </Grid>
              )}
            </Grid>
            <input
              {...register(
                `pediatricalVaccinationRecords.${index}.vaccinationDate`
              )}
              type="date"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              disabled={disableCondition}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
export default PediatricalSection;
