import React from "react";
import { Grid } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
export const ClinicDetailsForm = ({
  register,
  fields,
  errors,
  isEditable,
  disableCondition,
  onAddHandler,
  onRemoveHandler,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className="flex justify-end">
          {!disableCondition && (
            <ControlPointIcon
              sx={{ cursor: "pointer" }}
              onClick={onAddHandler}
            />
          )}
        </div>
      </Grid>

      {fields.map((record, index) => (
        <React.Fragment key={record.id}>
          {index !== 0 && !disableCondition && (
            <Grid item xs={12}>
              <RemoveCircleOutlineIcon
                sx={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginBottom: "3px",
                  float: "right",
                }}
                onClick={() => onRemoveHandler(index)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <label className="text-sm mb-2 block">Clinic Name</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              placeholder="Enter clinic name.."
              {...register(`clinics.${index}.clinic_name`, {
                required: "Clinic name is required",
              })}
            />
            {errors.Clinics?.[index]?.clinic_name && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Clinics[index].clinic_name.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <label className="text-sm mb-2 block">Clinic Address</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              placeholder="Enter clinic address.."
              {...register(`clinics.${index}.clinic_address`, {
                required: "Clinic address is required",
              })}
            />
            {errors.Clinics?.[index]?.clinic_address && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Clinics[index].clinic_address.message}
              </p>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
