import React from "react";
import { useSelector } from "react-redux";
import {
  Availabilities,
  Experiences,
  feeOptions,
} from "../../Utils/StaticData";

export default function SearchFilter({ setValue, getValues }) {
  const { doctorspecialityList } = useSelector((state) => state.doctor);
  const handleCheckboxChange = (event, speciality) => {
    const checked = event.target.checked;
    if (checked) {
      setValue("speciality", speciality);
    } else {
      setValue("speciality", "");
    }
  };
  const handleRadioChange = (event, fieldName) => {
    setValue(fieldName, event.target.value);
  };
  return (
    <>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-3">Category</h3>
        <div className="bg-white p-4 rounded-xl shadow-lg border">
          {doctorspecialityList.map((itemx) => (
            <div className="py-1">
              <label>
                <input
                  type="checkbox"
                  value={itemx.name}
                  checked={getValues("speciality") === itemx.name }
                  onChange={(e) => handleCheckboxChange(e, itemx.name)}
                />{" "}
                {itemx.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-3">Fees</h3>
        <div className="bg-white p-4 rounded-xl shadow-lg border">
          {feeOptions.map((itx) => (
            <div className="py-1">
              <label>
                <input
                  type="radio"
                  value={itx}
                  checked={getValues("fees") === itx ? true : false}
                  onChange={(e) => handleRadioChange(e, "fees")}
                />{" "}
                {itx}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-3">Availability</h3>
        <div className="bg-white p-4 rounded-xl shadow-lg border">
          {Availabilities.map((itx) => (
            <div className="py-1">
              <label>
                <input
                  type="radio"
                  value={itx}
                  checked={getValues("availability") === itx ? true : false}
                  onChange={(e) => handleRadioChange(e, "availability")}
                />{" "}
                {itx}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-3">Experience</h3>
        <div className="bg-white p-4 rounded-xl shadow-lg border">
          {Experiences.map((itx) => (
            <div className="py-1">
              <label>
                <input
                  type="radio"
                  value={itx}
                  checked={getValues("experience") === itx ? true : false}
                  onChange={(e) => handleRadioChange(e, "experience")}
                />{" "}
                {itx + " " + "Years of experience"}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
