import React, {useState} from 'react'
import BannerDashboard from './BannerDashboard'
import { Grid } from '@mui/material'
import ToggleNavs from '../ToggleNavs/toggleNavs';

export default function Notification() {
    const [isPushChecked, setIsPushChecked] = useState(false);
    const [isEmailChecked, setIsEmailChecked] = useState(false);
  
    const handlePushToggle = () => {
      setIsPushChecked(!isPushChecked);
    };
  
    const handleEmailToggle = () => {
      setIsEmailChecked(!isEmailChecked);
    };
  
  return (
    <>
    <BannerDashboard pageTitle="Notification Preferences"/>
    <section className="py-8 bg-slate-50">
            <div className="container mx-auto px-4">
                <Grid container columns={12} spacing={4}>
                    <Grid item xs={12} md={4} lg={3}>
                       <ToggleNavs />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <h2 className='text-2xl font-semibold  pb-3 mb-6 border-b'>Notification Preferences</h2>
                        <div className='border rounded-lg shadow-lg'>
                            <div className='p-6 flex justify-between border-b items-center'>
                                <div className='lg:pr-32'>
                                    <h2 className='font-semibold text-lg mb-2'>Push Notification</h2>
                                    <p className='text-sm text-gray-600'>If you chose to turn this off, you will stop receiving app notifications related to daily healthcare tips and promotional offers.</p>
                                </div>
                                <div className='pl-3'>
                                    <input
                                        type="checkbox"
                                        id="toggleSwitch"
                                        className="hidden"
                                        checked={isPushChecked}
                                        onChange={handlePushToggle}
                                    />
                                    <label htmlFor="toggleSwitch" className="cursor-pointer">
                                        <div
                                        className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
                                            isPushChecked ? 'bg-primary' : 'bg-gray-300'
                                        }`}
                                        >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                                                isPushChecked ? 'translate-x-6' : ''
                                            }`}
                                        ></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='p-6 flex justify-between items-center'>
                                <div className='lg:pr-32'>
                                    <h2 className='font-semibold text-lg mb-2'>SMS Notification</h2>
                                    <p className='text-sm text-gray-600'>If you chose to turn this off, you will stop receiving app notifications related to daily healthcare tips and promotional offers.</p>
                                </div>
                                <div className='pl-3'>
                                    <input
                                        type="checkbox"
                                        id="smsNotification"
                                        className="hidden"
                                        checked={isEmailChecked}
                                        onChange={handleEmailToggle}
                                    />
                                    <label htmlFor="smsNotification" className="cursor-pointer">
                                        <div
                                        className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
                                            isEmailChecked ? 'bg-primary' : 'bg-gray-300'
                                        }`}>
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                                                isEmailChecked ? 'translate-x-6' : ''
                                            }`}></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    </>
  )
}
