import {
  signInSuccess,
  registerSuccess,
  logout,
  setError,
  clearError,
  changeUserPassword,
  clearMsg,
  storeUserLocation,
  forgetPassword,
} from "../Actions/Auth/authActions";
import axios from "axios";
import Cookies from "js-cookie";
import { Constants, placeDetails} from "../Constants";
export const signIn = (userData) => async (dispatch) => {
  dispatch(clearError());
  try {
    const response = await axios.post(`${Constants.signIn}`, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { token, userInfo } = response.data;
    localStorage.setItem("token", token);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    dispatch(signInSuccess(response.data));
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};

export const registerUser = (userData) => async (dispatch) => {
  dispatch(clearError());
  try {
    const response = await axios.post(`${Constants.register}`, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(registerSuccess(response.data));
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};

export const addCoverPic = (formData) => async (dispatch) => {
  dispatch(clearError());
  try {
    const response = await axios.post(`${Constants.addCoverPic}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { coverImg } = response.data;
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("userInfo")),
        coverImg,
      })
    );
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};

export const StoreUserLocation = (data) => async (dispatch) => {
  try {
    let response = await axios.get(
      `${placeDetails}?latlng=${data.latitude},${data.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data.results && response.data.results.length > 0) {
      const addressComponents = response.data.results[0].address_components;
      const localityComponent = addressComponents.find((component) =>
        component.types.includes("sublocality_level_1")
      );
      const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const localityName = localityComponent ? localityComponent.long_name : "location not found";

     
      const cityName = cityComponent ? cityComponent.long_name : "City not Found";
      Cookies.set("userLocality", localityName, { expires: 1 });
      Cookies.set("userCity", cityName, { expires: 1 });
      dispatch(storeUserLocation(localityName));
    }
  } catch (error) {
    return error
  }
};

export const addProfilePic = (formData) => async (dispatch) => {
  dispatch(clearError());
  try {
    const response = await axios.post(`${Constants.addProfilePic}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { profilePic } = response.data;
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("userInfo")),
        profilePic,
      })
    );
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};

export const changePassword = (data) => async (dispatch) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const response = await axios.put(`${Constants.changePassword}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(changeUserPassword(response.data));
    setTimeout(() => {
      dispatch(clearMsg());
    }, 1500);
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};

export const ForgetPassword = (data) => async (dispatch) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const response = await axios.post(`${Constants.forgetPassword}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(forgetPassword(response.data))
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
}

export const Logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  dispatch(logout());
};
