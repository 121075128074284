
import React from 'react'
import Specialityblock from './Specialityblock'
import { Link } from 'react-router-dom'
export default function Specialities() {
  return (
    <>
        <section className='py-12 px-4'>
            <div className='container mx-auto flex items-center justify-between mb-16'>
                <h2 className='font-bold lg:text-4xl pagetitle relative text-xl'>Specialities</h2>
                <div>
                    <Link to="/specialities" className='inline-block border-2 rounded-full md:px-5 md:py-3 border-primary text-primary font-bold hover:bg-blue-700 hover:text-white text-center p-2 md:text-base text-xs'>See All Specialities</Link>
                </div>
            </div>
            <div className='container mx-auto'>
                <Specialityblock page={'home'}/>
            </div>
        </section>
        
    </>
  )
}
