import { useSelector } from "react-redux";

export const AppointmentDetails = ({ data }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="rounded-xl shadow-lg bg-white overflow-hidden border">
      <div className="py-4 px-5">
        <h3 className="font-semibold text-lg mb-5">Appointment Details</h3>
        <div className="flex gap-3 mb-2">
          <span>
            <i class="fa-solid fa-user-doctor text-cyan-400"></i>
          </span>
          <p>{data.Name}</p>
        </div>
        <div className="flex gap-3 mb-2">
          <span>
            <i class="fa-solid fa-user text-blue-800"></i>
          </span>
          <p>
            {user.Name}
            <span className="inline-block py-1 px-3 text-xs text-white bg-emerald-600 rounded-full ml-2">
              Patient{" "}
            </span>
          </p>
        </div>
        <div className="flex gap-3 mb-2">
          <span>
            {data.appointment_type === "Clinic" ? (
              <i class="fa-solid fa-square-plus text-red-600"></i>
            ) : (
              <i class="fa-solid fa-video text-blue-500"></i>
            )}
          </span>
          <p>
            {data.appointment_type === "Clinic"
              ? "Clinic Visit"
              : "Video Consult"}
          </p>
        </div>
        <div className="flex gap-3 mb-2">
          <span>
            <i class="fa-regular fa-calendar-days text-green-700"></i>
          </span>
          <p>{data.appointment_date} {data.appointment_time.substring(0,8)}</p>
        </div>
        {data.appointment_type === 'Clinic' && (
        <div className="flex gap-3 mb-2">
          <span>
            <i class="fa-sharp fa-solid fa-location-dot text-red-900"></i>
          </span>
          <p>
            {data.appointment_address}
          </p>
        </div>)}
      </div>
    </div>
  );
};
