import axios from "axios";
import { Constants } from "../Constants";
import { fetchAllFaqs } from "../Actions/FAQ/faqAction";


export const FetchAllFaq = () => async (dispatch) => {
  try {
    const response = await axios.get(`${Constants.fetchAllFaqQuestions}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(fetchAllFaqs(response.data));
  } catch (error) {
    return error;
  }
};


