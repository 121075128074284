import {
  ADD_DOCTOR_DATA,
  SET_ERROR,
  CLEAR_ERROR,
  CLEAR_MSG,
  GET_DOCTOR_PROFILE,
  ADD_DOCTOR_AVAILABILITY,
  GET_DOCTOR_AVAILABILITIES,
  GET_DOCTOR_SPECIALITITY_INFO,
  GET_ALL_DOCTOR_LIST,
  ADD_DOCTOR_REVIEWS,
  FETCH_ALL_UPCOMING_APPOINTMENTS
} from "../Actions/Doctor/types";

const initialState = {
  doctorData: {},
  doctorAppointments: [],
  doctorLists : [],
  doctorspecialityList: [],
  msg: null,
  error: null,
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DOCTOR_DATA:
      return {
        ...state,
        msg: action.payload.message,
      };
    case GET_DOCTOR_PROFILE:
      return {
        ...state,
        doctorData: action.payload,
      };

    case ADD_DOCTOR_REVIEWS:
       return {
        ...state,
        msg:action.payload.message
       }
    case ADD_DOCTOR_AVAILABILITY:
      return {
        ...state,
        msg: action.payload.message,
      };
    case GET_ALL_DOCTOR_LIST:
      return {
        ...state,
        doctorLists:action.payload
      }
    case GET_DOCTOR_SPECIALITITY_INFO:
      return {
        ...state,
        doctorspecialityList: action.payload,
      };
    case GET_DOCTOR_AVAILABILITIES:
      return {
        ...state,
        doctorAvailabilities: action.payload,
      };
    case FETCH_ALL_UPCOMING_APPOINTMENTS:
      return {
        ...state,
        doctorAppointments: action.payload
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_MSG:
      return {
        ...state,
        msg: null,
      };
    default:
      return state;
  }
};

export default doctorReducer;
