import axios from 'axios'
import { Constants } from '../Constants';
import { addDoctorData, clearError, clearMsg, getDoctorProfile, setError, addDoctorAvailability, getDoctorAvailabilities, getDoctorSpecialityInfo, getAllDoctorLists, addDoctorReviews, fetchAllUpcomingAppointments} from '../Actions/Doctor/doctorActions';

export const addDoctorProfile = (userData) => async (dispatch,getState) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const token = getState().auth.token
    const response = await axios.post(`${Constants.addDoctorProfile}`, userData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
    });
    dispatch(addDoctorData (response.data));
    setTimeout(() => {
      dispatch(clearMsg());
    }, 1000);
  } catch (error) {
    dispatch(setError(error.response.data.message));

  }
};

export const AddDoctorReviews = (userData) => async (dispatch, getState) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const token = getState().auth.token
    const response = await axios.post(`${Constants.addDoctorReviews}`, userData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    dispatch(addDoctorReviews(response.data));
    setTimeout(() => {
      dispatch(clearMsg());
    }, 1000);
  } catch (error) {
    dispatch(setError(error.response.data.message));

  }
}

export const AddDoctorAvailability = (userData) => async (dispatch,getState) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const token = getState().auth.token
    const response = await axios.post(`${Constants.addDoctorAvailability}`, userData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    dispatch(addDoctorAvailability(response.data));
    setTimeout(() => {
      dispatch(clearMsg());
    }, 1000);
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
}

export const GetDoctorProfile = (userData) => async (dispatch,getState) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const token = getState().auth.token
    const response = await axios.get(`${Constants.GetDoctorProfile}${userData}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    dispatch(getDoctorProfile(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
}

export const GetDoctorAvailabilities = (userData) => async (dispatch,getState) => {
  dispatch(clearError());
  dispatch(clearMsg());
  try {
    const token = getState().auth.token
    const response = await axios.get(`${Constants.GetDoctorAvailabilities}${userData}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        
      },
    });
     dispatch(getDoctorAvailabilities(response.data));
     return response.data;
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
}

export const GetDoctorSpecialityInfo = () => async (dispatch) => {
  try {
  const response = await axios.get(`${Constants.GetDoctorSpecialitiesInfo}`,{
    headers: {
      'Content-Type': 'application/json',
    },
  });
   dispatch(getDoctorSpecialityInfo(response.data));
} catch (error) {
  dispatch(setError(error.response.data.message));
}
}
export const GetAllDoctorLists = () => async (dispatch) => {
  try {
  const response = await axios.get(`${Constants.GetAllDoctorLists}`,{
    headers: {
      'Content-Type': 'application/json',
    },
  });
   dispatch(getAllDoctorLists (response.data));
} catch (error) {
  return error
}
}

export const FetchAllUpcomingAppointment = (userData) => async (dispatch, getState) => {
  try {
    let token = getState().auth.token;
    const response = await axios.get(
      `${Constants.fetchAllUpcomingAppointment}${userData.Id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchAllUpcomingAppointments(response.data.Appointments));
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};


