import React, { useEffect } from "react";
import BannerDashboard from "./BannerDashboard";
import { Grid } from "@mui/material";
import ToggleNavs from "../ToggleNavs/toggleNavs";
import { useDispatch, useSelector } from "react-redux";
import { FetchAllTransaction } from "../../Thunk/patientThunks";
import moment from "moment";

export default function Transactions() {
  const dispatch = useDispatch();
  const { transactions } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(FetchAllTransaction({ Id: user.PatientId }));
  }, []);
  return (
    <>
      <BannerDashboard pageTitle="Transactions and Payments" />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h2 className="text-2xl font-semibold  pb-3 mb-6 border-b">
                Transactions and Payments
              </h2>
              <div className="overflow-x-auto">
                <table className="border table-auto w-full text-xs shadow-lg">
                  <thead>
                    <tr>
                      <th className="border-b dark:border-slate-800 font-medium px-4 py-4 text-black text-left">
                        BookingID
                      </th>
                      <th className="border-b dark:border-slate-800 font-medium px-4 py-4 text-black text-left">
                        Payment Date
                      </th>
                      <th className="border-b dark:border-slate-800 font-medium px-4 py-4 text-black text-left">
                        Payment Status
                      </th>
                      <th className="border-b dark:border-slate-800 font-medium px-4 py-4 text-black text-left">
                        Payment Amount
                      </th>
                      <th className="border-b dark:border-slate-800 font-medium px-4 py-4 text-black text-left">
                        Payment method
                      </th>
                      <th className="border-b dark:border-slate-800 font-medium px-4 py-4 text-black text-left">
                        Transaction ID
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-slate-800">
                    {transactions.length > 0 ? (
                      transactions.map((itx) => (
                        <tr key={itx.bookingId}>
                          <td className="text-left border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">
                            {itx.bookingId}
                          </td>
                          <td className="text-left border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">
                            {moment(itx.paymentDate).format("DD-MM-YYYY")}
                          </td>
                          <td className="text-left border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">
                            {itx.paymentStatus}
                          </td>
                          <td className="text-left border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">
                            {itx.PaymentAmount} (USD)
                          </td>
                          <td className="text-left border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">
                            {itx.PaymentMethod}
                          </td>
                          <td className="text-left border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">
                            {itx.TransactionId}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={6}
                          className="text-center text-white font-bold py-3"
                        >
                          No Transactions Records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
