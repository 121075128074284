import SidebarNav from "../Dashboard/SidebarNav";
import { useDispatch, useSelector } from "react-redux";
import DoctorNav from "../Dashboard/DoctorNav";
import { addCoverPic, addProfilePic, Logout } from "../../Thunk/authThunks";
import {Constants} from "../../Constants";
import React from "react";
import { useNavigate } from "react-router-dom";
const ToggleNavs = () => {
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = React.useState(
    user && user.profilePic
      ? Constants.getImg + user.profilePic
      : user.userType === "Doctor"
      ? "assets/images/dr1.jpg"
      : "assets/images/user.jpg"
  );
  const [coverPic, setCoverPic] = React.useState(
    user && user.coverImg
      ? Constants.getImg + user.coverImg
      : user.userType === "Doctor"
      ? "assets/images/leaderbanner.png"
      : "assets/images/cover-01.png"
  );

  const logout = () => {
    dispatch(Logout());
    navigate("/");
  };

  const handleProfileChange = (e) => {
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);
    formData.append("userId", user.Id);
    setProfilePic(URL.createObjectURL(e.target.files[0]));
    dispatch(addProfilePic(formData));
  };

  const handleCoverChange = (e) => {
    const formData = new FormData();
    formData.append("coverImg", e.target.files[0]);
    formData.append("userId", user.Id);
    setCoverPic(URL.createObjectURL(e.target.files[0]));
    dispatch(addCoverPic(formData));
  };

  return user && token && user.userType === "Doctor" ? (
    <DoctorNav
      LogoutHandler={logout}
      profilePic={profilePic}
      coverPic={coverPic}
      onProfileChange={handleProfileChange}
      onCoverChange={handleCoverChange}
    />
  ) : (
    <SidebarNav
      LogoutHandler={logout}
      profilePic={profilePic}
      coverPic={coverPic}
      onProfileChange={handleProfileChange}
      onCoverChange={handleCoverChange}
    />
  );
};
export default ToggleNavs;
