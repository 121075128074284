import React, { useEffect, useMemo, useState } from "react";
import BannerDashboard from "../BannerDashboard";
import { Grid } from "@mui/material";
import ToggleNavs from "../../ToggleNavs/toggleNavs";
import ListMedicalRecord from "./listRecords";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchPatientMedicalRecord } from "../../../Thunk/patientThunks";
import moment from "moment";
import toast from "react-hot-toast";
import { clearMsg } from "../../../Actions/Patient/userActions";
export default function MedicalRecord() {
  const navigation = useNavigate();
  const [selectedYear, setSelectedYear] = useState("All");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { medicalRecords, msg } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(FetchPatientMedicalRecord({ patientId: user.PatientId }));
  }, [msg]);

  const currentYear = moment().year();
  const years = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) =>
      moment()
        .year(currentYear + i)
        .format("YYYY")
    );
  }, [currentYear]);

  const records = useMemo(() => {
    if (selectedYear === "All") {
      return medicalRecords;
    } else {
      return medicalRecords.filter(
        (itx) => moment(itx.DateOfRecord).format("YYYY") === selectedYear
      );
    }
  }, [selectedYear, medicalRecords]);

  useEffect(() => {
    if(msg) {
      toast.success(msg);
      setTimeout(() => {
       dispatch(clearMsg());
      },1300)
    }

  },[msg])

  return (
    <>
      <BannerDashboard pageTitle="My Medical Record" />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <div className="flex justify-between ">
                <h2 className="text-2xl font-semibold  pb-3 mb-6 ">
                  My Medical Record
                </h2>
                <button
                  type="button"
                  onClick={() => navigation("/add-medical-record")}
                  className="px-2 h-[40px] rounded-full bg-blue-600 text-white text-sm"
                >
                  Add Records
                </button>
              </div>
              <div className="flex p-2 bg-white ">
                <button
                  className={`px-8 py-2 rounded-full ${
                    selectedYear === "All"
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => setSelectedYear("All")}
                >
                  All
                </button>
                {years.map((year) => (
                  <button
                    key={year}
                    className={`px-4 py-2 mx-2 rounded-full ${
                      selectedYear === year
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                    onClick={() => setSelectedYear(year)}
                  >
                    {year}
                  </button>
                ))}
              </div>
              {records.length > 0 ? (
                records.map((itx) => (
                  <ListMedicalRecord
                    recordItem={itx}
                   
                  />
                ))
              ) : (
                <h2 className="text-lg text-center font-semibold">
                  No Medical Records Found !
                </h2>
              )}
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
