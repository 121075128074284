import React from 'react'
import InnerBanner from '../InnerBannersearch/InnerBannersearch'
import Specialityblock from './Specialityblock'

export default function SpecialitiesAll() {
  return (
    <>
        <InnerBanner/>
        <section className=' py-20'>
            <div className='container mx-auto px-4'>
                <h1 class="font-bold lg:text-4xl relative text-2xl mb-12 text-center">All Specialities</h1>
                <Specialityblock/>
            </div>
        </section>
    </>
  )
}
