import React, { useState, useRef, useEffect } from "react";
import "./Multiselect.css";

const MultiSelectDropdown = ({
  options,
  setValue,
  name,
  selectedOptions,
  setSelectedOptions,
  fieldDisability,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  useEffect(() => {
    setValue(name, selectedOptions);
  }, [selectedOptions]);

  return (
    <div ref={dropdownRef} className="dropdown-container">
      <div
        className="dropdown-header border border-slate-400 px-3 min-h-11 rounded-xl w-full text-sm"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOptions.length === 0 ? "Select" : selectedOptions.join(", ")}
        <span className="dropdown-arrow">{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option, index) => {
            const optionValue =
              typeof option === "object" && option !== null
                ? option.name
                : option;

            return (
              <li key={index} className="dropdown-list-item">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(optionValue)}
                    onChange={() => handleOptionChange(optionValue)}
                    disabled={fieldDisability}
                  />
                  {optionValue}
                </label>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
