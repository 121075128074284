import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewList from "./ReviewList";
import ReviewStar from "./ReviewStar";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AddDoctorReviews } from "../../Thunk/doctorThunks";
import { Constants } from "../../Constants";
import moment from "moment";
import { Link } from "react-router-dom";
export default function DrinfoTab({ data }) {
  const [activeTab, setActiveTab] = useState("moreinfo");
  const [isReview, setReview] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { msg } = useSelector((state) => state.doctor);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    event.preventDefault();
    setReview(true);
  };
  useEffect(() => {
    if (msg) {
      toast.success(msg);
    }
  }, [msg]);
  const handleReviewSubmit = () => {
    dispatch(
      AddDoctorReviews({
        Id: data.DoctorId,
        stars: rating,
        reviewDescr: reviewText,
        reviewDateTime: new Date(),
        reviewUser: user.Name,
      })
    );
    setRating(0);
    setReviewText("");
    setReview(false);
  };
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex space-x-2">
          <button
            className={`py-2 px-4 ${
              activeTab === "moreinfo"
                ? "text-white bg-primary rounded-lg px-8 min-w-36"
                : "text-gray-600 bg-gray-200 rounded-lg px-8 min-w-36"
            }`}
            onClick={() => setActiveTab("moreinfo")}
          >
            More Info
          </button>
          <button
            className={`py-2 px-4 ${
              activeTab === "reviews"
                ? "text-white bg-primary rounded-lg px-8  min-w-36"
                : "text-gray-600 bg-gray-200 rounded-lg px-8 min-w-36"
            }`}
            onClick={() => setActiveTab("reviews")}
          >
            Review
          </button>
          <button
            className={`py-2 px-4 ${
              activeTab === "blog"
                ? "text-white bg-primary rounded-lg px-8  min-w-36"
                : "text-gray-600 bg-gray-200 rounded-lg px-8 min-w-36"
            }`}
            onClick={() => setActiveTab("blog")}
          >
            Blogs
          </button>
        </div>
      </div>
      <div className="mt-5">
        {activeTab === "moreinfo" && (
          <div>
            <h2 className="text-2xl font-bold my-4">{data.Name}</h2>
            <p className="mb-4">{data.Description}</p>

            <Grid container columns={12} spacing={4}>
              <Grid item lg={5}>
                <h3 className="font-semibold text-xl mb-4">Specialities</h3>
                <div className="flex flex-wrap">
                  {data.ProfessionalInformation.Specializations.map((itx) => (
                    <a
                      href="#"
                      className="px-5 bg-gray-200 rounded-full py-2 text-sm font-medium focus:outline-none m-1  text-gray-500 hover:text-gray-700"
                    >
                      {itx}
                    </a>
                  ))}
                </div>
              </Grid>
              <Grid item lg={7}>
                <h3 className="font-semibold text-xl mb-4">Language</h3>
                <div className="flex flex-wrap">
                  {data.languages_can_speak.map((itemx, index) => (
                    <div
                      key={index}
                      className={`px-5 rounded-full py-2 text-sm font-medium focus:outline-none m-1 text-gray-700 min-w-32 text-center ${
                        index % 2 === 0 ? "bg-red-200" : "bg-green-200"
                      }`}
                    >
                      {itemx}
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {activeTab === "reviews" && (
          <div>
            <div
              className={`${
                data.Ratings.length > 0 ? "justify-between" : "justify-end"
              } flex  items-center mb-3`}
            >
              {data.Ratings.length > 0 && (
                <div className="mb-3">
                  <select className="p-3 rounded-lg text-sm border">
                    <option>Sort by newest review</option>
                  </select>
                </div>
              )}
              <div className="mb-3">
                <a
                  href="#"
                  onClick={handleClick}
                  className="px-5 bg-primary rounded-full py-3 text-sm font-medium text-white  min-w-32 text-center"
                >
                  Write a Review
                </a>
              </div>
            </div>
            {isReview && (
              <form className="mb-8 border bg-white rounded-lg p-4">
                <h2 className="mb-4 font-semibold text-xl">Write a Review</h2>
                <ReviewStar rating={rating} setRating={setRating} />
                <textarea
                  className="w-full p-3 border rounded-lg mt-3"
                  placeholder="Write your review here..."
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
                <button
                  className="px-5 bg-primary rounded-full py-3 text-sm font-medium text-white mt-3"
                  onClick={handleReviewSubmit}
                >
                  Submit
                </button>
                <button
                  className="px-5 ms-4 bg-red-400 rounded-full py-3 text-sm font-medium text-white mt-3"
                  onClick={() => setReview(false)}
                >
                  Cancel
                </button>
              </form>
            )}
            {data.Ratings.length > 0 ? (
              data.Ratings.map((itx) => <ReviewList ratings={itx} />)
            ) : (
              <p className="text-lg font-semibold text-center">
                No Reviews Found
              </p>
            )}
          </div>
        )}
        {activeTab === "blog" && (
          <div className="mt-4">
            {data.Blogs?.length > 0 ? (
              <Grid container columns={12} spacing={2}>
                {data.Blogs.map((itx, index) => (
                  <Grid key={index} item lg={4} md={6} sm={12}>
                    <div className="relative">
                      <img
                        src={`${Constants.getImg}${itx.blogImage}`}
                        className="aspect-[4/3] object-cover rounded-[20px]"
                      />
                    </div>
                    <div className="p-4 bg-gray-100 rounded-[10px] w-11/12 m-auto mt-[-25px] relative hover:shadow-xl transition-all">
                      <div className="flex gap-3 items-center mb-3">
                        <div className="px-1 py-2 bg-primary text-xs text-white rounded-lg">
                          {moment(itx.blogPostDate).format("DD MMM, YYYY")}
                        </div>
                        <div className="text-xs">
                          <i class="fa-solid fa-user text-primary me-2"></i>
                          By{" "}
                          {itx.blogPostedBy[0].postedBy.substring(0, 10) + "..."}
                        </div>
                      </div>

                      <h2 className="text-md font-bold mb-3">
                        {itx.blogTitle}
                      </h2>
                      <div
                        className="text-sm text-gray-600 mb-3"
                        dangerouslySetInnerHTML={{
                          __html: itx.blogContent.substring(0, 62) + "..",
                        }}
                      ></div>
                      <Link to={`/blog-details/${itx._id}`} >
                        <p className="text-sm font-semibold text-primary">
                          <i class="fa-solid fa-right-long"></i> Read More
                        </p>
                      </Link>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className="flex w-full h-full justify-center mt-5">
                <p className="font-semibold">No Blogs Posted !</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
