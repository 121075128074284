import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";
import Rout from "./Router/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StoreUserLocation } from "./Thunk/authThunks";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(StoreUserLocation(position.coords));
        },
        (error) => {
          console.log('Location error',error.message);
        },
      );
    }
  }, []);
  return (
    <>
      <Header />
      <Rout />
      <Footer />
    </>
  );
}

export default App;
