import React from "react";
import {useSelector } from "react-redux";
import { CalculateAge } from "../../Utils/AgeCalculator";
const DoctorNav = ({
  LogoutHandler,
  coverPic,
  profilePic,
  onProfileChange,
  onCoverChange,
}) => {
  const { user } = useSelector((state) => state.auth);
  const {doctorData} = useSelector(state => state.doctor);

  return (
    <>
      <div className="dashboardNav shadow-lg bg-white">
        <div className="relative usercover">
          <img src={coverPic} className="h-50 object-cover w-full" />
          <label className="cameraCover">
            <input
              type="file"
              className="hidden"
              onChange={(e) => onCoverChange(e)}
            />
            <i class="fa-solid fa-camera"></i>
          </label>
        </div>
        <div className="mb-5">
          <div className="profileImg relative">
            <img src={profilePic} />
            <label className="cameraCover">
              <input
                type="file"
                className="hidden"
                onChange={(e) => onProfileChange(e)}
              />
              <i class="fa-solid fa-camera"></i>
            </label>
          </div>
          <h2 className="text-center font-semibold mt-2 mb-1 text-xl">
            {user && user.Name}
          </h2>
          {doctorData && doctorData?.LicensingInformation  ? (
            <>
          <h3 className="text-center text-md">
            License Number : <span className="font-semibold">{doctorData.LicensingInformation?.Medical_License_Number}</span>
          </h3>
          <h3 className="text-center text-md">{doctorData.Gender}, {CalculateAge(doctorData.DateOfBirth)}</h3>
          </>
        ):null}
        </div>
        <div className="border-t-2 border-slate-200 mb-4">
          <a
            href="/dr-dashboard"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Dashboard
          </a>
          <a
            href="/availability"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            Availability
          </a>
          <a
            href="/blog-List"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            My Blogs
          </a>
          <a
            href="/my-patients-appointment"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            My Appointments
          </a>
          <a
            href="/transaction"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Transactions and Payments
          </a>
          <a
            href="/notification"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Notification Preferences
          </a>
          <a
            href="/change-password"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Change Password
          </a>
          <a
            href="#"
            onClick={() => LogoutHandler()}
            className="block text-primary py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Logout
          </a>
        </div>
      </div>
    </>
  );
};
export default DoctorNav;
