import { Grid, Rating } from "@mui/material";
import React from "react";
import { Constants } from "../../Constants";
import { GetReviewStarRating } from "../../Utils/getReviewStarRating";

export default function Drinfo({ data }) {
  return (
    <>
      <div className="border rounded-xl p-5 bg-white">
        <Grid container columns={12} spacing={4} className="items-center">
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img
              src={`${Constants.getImg}${data.profilePic}`}
              className="w-48 h-48 rounded-full border-2 cover mx-auto"
            />
          </Grid>
          <Grid item lg={8} md={8} sm={12}>
            <h2 className="text-2xl font-bold my-2">{data.Name}</h2>
            <p className="my-1">{data.ProfessionalInformation.Degree}</p>
            <p className="my-1">
              {data.ProfessionalInformation.work_experience} years experience
            </p>
            <p className="my-1">
              $ {data.Fares.clinic_fare} Consultation fee at clinic
            </p>
            <p className="my-1">
              <i className="fa-solid text-orange-800 fa-location-dot"></i>{" "}
              {data.Address}
            </p>
            {data.Ratings?.length > 0 && (
              <div className="flex items-center mt-3 text-sm">
                <Rating size="small" defaultValue={GetReviewStarRating(data.Ratings)} precision={0.5}  readOnly/>
                <div className="ml-3 rounded-full bg-indigo-800 px-2 text-white text-xs py-0">
                  {GetReviewStarRating(data.Ratings)}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
