import { Grid } from "@mui/material";
const SpecificOrganSection = ({ register, disableCondition }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Organ/System</label>
        <select
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("organ_system")}
          disabled={disableCondition}
        >
          <option>Select</option>
          <option>Cardiocirculatory system</option>
          <option> Respiratory system</option>
          <option>Endocrine system</option>
          <option>Neurological System</option>
          <option>Dermatological diseases</option>
          <option>Infectious diseases</option>
          <option>Urogenital system</option>
          <option>Renal disorders </option>
          <option>Blood and haematopoietic organs</option>
          <option>Osteoarticular system </option>
          <option>Sense organs(sight, smell, hearing)</option>
          <option>Physical medicine and rehabilitation</option>
        </select>
      </Grid>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Pathology Description</label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("pathology_description")}
          disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Date of Diagnosis</label>
        <input
          type="date"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("DtOfDiagnosis")}
          disabled={disableCondition}
        />
      </Grid>

      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Treatment History</label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("TreatmentHistory")}
           disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={6}>
        <label className="text-sm mb-2 block">Current Status</label>
        <select
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("CurrentStat")}
          disabled={disableCondition}
        >
          <option>Select</option>
          <option>Recovered</option>
          <option>Chronic</option>
          <option>Active</option>
        </select>
      </Grid>
    </Grid>
  );
};
export default SpecificOrganSection;
