
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";

const TimezoneDropdown = ({timezone, handleTimezoneChange}) => {

  const [utcTime, setUtcTime] = useState(moment.utc().format("HH:mm A"));

  useEffect(() => {
    const interval = setInterval(() => {
      setUtcTime(moment.utc().format("HH:mm A"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);



  const timezones = moment.tz.names();

  return (
    <div>
      <label
        htmlFor="timezone-select"
        className="mb-1 block font-semibold text-sm"
      >
        Select Timezone:
      </label>
      <select
        className="border border-slate-300 px-3 h-11 rounded-xl w-full text-sm"
        value={timezone}
        onChange={handleTimezoneChange}
      >
        {timezones.map((tz) => (
          <option key={tz} value={tz}>
            {tz} - {moment.utc().tz(tz).format("HH:mm A")}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimezoneDropdown;
