import React, { useEffect } from "react";
import BannerDashboard from "../BannerDashboard";
import { Grid } from "@mui/material";
import ToggleNavs from "../../ToggleNavs/toggleNavs";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addPatientMedicalProfile, EditPatientMedicalRecord } from "../../../Thunk/patientThunks";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { clearMsg } from "../../../Actions/Patient/userActions";
const AddMedicalRecord = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { state } = location;
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    values: {
      Title: state?.Title || "",
      DtOfRecord: state?.DateOfRecord || "",
      shortDescr: state?.ShortDescription || "",
    },
  });
  const dispatch = useDispatch();
  const { msg, error } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  const handleAddRecords = (data) => {
    const formData = new FormData();
    if (state?.isUpdated === true) {
      const updatedBody = {
        Title:data.Title,
        DateOfRecord: data.DtOfRecord,
        ShortDescription:data.shortDescr,
        isUpdated:true
      }
      if (data.medicalDocument[0] === undefined) {
        formData.append("medicalDocument", state.Attachment);
      } else {
        formData.append("medicalDocument", data.medicalDocument[0]);
      }
      formData.append("Id", user.PatientId);
      formData.append("recordId", state._id);
      formData.append("updates", JSON.stringify(updatedBody) );
      dispatch(EditPatientMedicalRecord(formData));
    } else {
      formData.append("PatientId", user.PatientId);
      formData.append("Title", data.Title);
      formData.append("medicalDocument", data.medicalDocument[0]);
      formData.append("DateOfRecord", data.DtOfRecord);
      formData.append("ShortDescription", data.shortDescr);
      formData.append("isUpdated", true);
      dispatch(addPatientMedicalProfile(formData));
    }
  };

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      setTimeout(() => {
       navigation('/my-medical-record')
       dispatch(clearMsg())
      },1300)
     
    }
    if (error) {
      toast.error(error)
    }
  }, [msg,error]);

  return (
    <>
      <BannerDashboard
        pageTitle={
          state?.isUpdated === true
            ? "Edit Medical Record"
            : "Add Medical Record"
        }
      />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h2 className="text-2xl font-semibold  pb-3 mb-6 ">
                {state?.isUpdated === true
                  ? "Edit Medical Record"
                  : "Add Medical Record"}
              </h2>
              <form onSubmit={handleSubmit(handleAddRecords)}>
                <div className="px-5">
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">Title</label>
                      <input
                        type="text"
                        placeholder="Enter a title"
                        {...register("Title", { required: "Enter some title" })}
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.Title && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Title.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">Attachment</label>
                      {state?.Attachment && (
                        <>
                          <label className="text-sm  block">
                            Previous Uploaded Document
                          </label>
                          <p className="text-sm mb-3">
                            {state?.Attachment.substring(
                              13,
                              state?.Attachment.length
                            )}
                          </p>
                        </>
                      )}
                      <label for="file-input" class="sr-only">
                        Choose file
                      </label>
                      <input
                        type="file"
                        {...register("medicalDocument", {
                          validate: (value) => {
                            if (state?.Attachment || value.length > 0) {
                              return true;
                            }
                            return "please add your medical document";
                          },
                         
                        })}
                        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                            file:bg-gray-50 file:border-0
                            file:me-4
                            file:py-3 file:px-4"
                      />
                      {errors.medicalDocument && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.medicalDocument.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Date of Record
                      </label>
                      <input
                        type="date"
                        {...register("DtOfRecord", {
                          required: "enter date of record",
                        })}
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.DtOfRecord && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.DtOfRecord.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Short Description
                      </label>
                      <input
                        type="text"
                        placeholder="Write a short description...."
                        {...register("shortDescr", {
                          required: "enter some description",
                        })}
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.shortDescr && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.shortDescr.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                </div>

                <div className="flex  mt-4 justify-end border-t-2 pt-4 border-slate-300 ">
                  <button
                    type="submit"
                    className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
                  >
                    {state?.isUpdated === true ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default AddMedicalRecord;
