export const ADD_DOCTOR_DATA = 'ADD_DOCTOR_DATA';
export const ADD_DOCTOR_AVAILABILITY = 'ADD_DOCTOR_AVAILABILITY';
export const GET_DOCTOR_PROFILE = 'GET_DOCTOR_PROFILE';
export const GET_DOCTOR_AVAILABILITIES = 'GET_DOCTOR_AVAILABILITIES';
export const GET_DOCTOR_SPECIALITITY_INFO = 'GET_DOCTOR_SPECIALITITY_INFO'
export const GET_ALL_DOCTOR_LIST = 'GET_ALL_DOCTOR_LIST'
export const ADD_DOCTOR_REVIEWS = 'ADD_DOCTOR_REVIEWS'
export const FETCH_ALL_UPCOMING_APPOINTMENTS = 'FETCH_ALL_UPCOMING_APPOINTMENTS'
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_MSG = 'CLEAR_MSG';