import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Aboutus from "../Component/About/Aboutus";
import Signin from "../Component/Signin/Signin";
import Register from "../Component/Register/Register";
import Dashboard from "../Component/Dashboard/Dashboard";
import { ProtectedRoute } from "../ProtectedRoutes/protectedRoute";
import { PublicRoute } from "../ProtectedRoutes/publicRoute";
import Whoweare from "../Component/Whoweare/Whoweare";
import Finddoctor from "../Component/Finddoctor/Finddoctor";
import Leadership from "../Component/Leadership/Leadership";
import DoctorDetails from "../Component/DoctorDetails/DoctorDetails";
import Checkout from "../Component/Checkout/Checkout";
import Myfavourite from "../Component/Dashboard/Myfavourite";
import AppointmentPatients from "../Component/Dashboard/AppointmentPatients";
import Transactions from "../Component/Dashboard/Transactions";
import Notification from "../Component/Dashboard/Notification";
import ChangePassword from "../Component/Dashboard/ChangePassword";
import Faqinner from "../Component/Faq/Faqinner";
import Forgotpassword from "../Component/Forgotpassword/Forgotpassword";
import DrDashboard from "../Component/Dashboard/DrDashboard";
import DrAvailability from "../Component/Dashboard/DrAvailability";
import { ProtectedDocRoute } from "../ProtectedRoutes/protectedDocRoute";
import SpecialitiesAll from "../Component/Specialities/SpecialitiesAll";
import AppointmentDoctor from "../Component/Dashboard/AppointmentDoctor";
import Blogdetails from "../Component/Blog/blogDetails";
import { Blog } from "../Component/Blog/blog";
import { AddBlog } from "../Component/Blog/addBlog";
import { BlogList } from "../Component/Blog/blogList";
import MedicalRecord from "../Component/Dashboard/MedicalRecord/MedicalRecord";
import AddMedicalRecord from "../Component/Dashboard/MedicalRecord/AddRecords";
const Rout = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route
        path="/signin"
        element={
          <PublicRoute>
            <Signin />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/who-we-are" element={<Whoweare />} />
      <Route path="/find-doctor" element={<Finddoctor />} />
      <Route path="/leadership" element={<Leadership />} />
      <Route
        path="/dr-details"
        element={
          <ProtectedRoute>
            <DoctorDetails />
          </ProtectedRoute>
        }
      />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/faq" element={<Faqinner />} />
      <Route path="/forgot-password" element={<Forgotpassword />} />
      <Route path="/specialities" element={<SpecialitiesAll />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/my-favourite"
        element={
          <ProtectedRoute>
            <Myfavourite />
          </ProtectedRoute>
        }
      />
      <Route path="/my-medical-record" element={<MedicalRecord />} />
      <Route path="/add-medical-record" element={<AddMedicalRecord />} />
      <Route path="/my-appointments" element={<AppointmentPatients />} />
      <Route path="/transaction" element={<Transactions />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route
        path="/add-blog"
        element={
          <ProtectedDocRoute>
            <AddBlog />
          </ProtectedDocRoute>
        }
      />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-details/:id" element={<Blogdetails />} />
      <Route path="/blog-List" element={<BlogList />} />
      <Route
        path="/dr-dashboard"
        element={
          <ProtectedDocRoute>
            <DrDashboard />
          </ProtectedDocRoute>
        }
      />
      <Route
        path="/my-patients-appointment"
        element={
          <ProtectedDocRoute>
            <AppointmentDoctor />
          </ProtectedDocRoute>
        }
      />
      <Route
        path="/availability"
        element={
          <ProtectedDocRoute>
            <DrAvailability />
          </ProtectedDocRoute>
        }
      />
    </Routes>
  );
};

export default Rout;
