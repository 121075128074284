const LastMeasuredSection = ({ register, fieldName, disableCondition }) => {
  return (
    <>
      <label className="text-sm mb-2 block">Last Measured</label>
      <div class="flex items-center me-4">
        <input
          type="radio"
          value="in last three months"
          {...register(fieldName)}
          disabled={disableCondition}
        />
        <label for="inline-radio" class="ms-2 text-sm font-medium">
          in last three months
        </label>
      </div>
      <div class="flex items-center me-4">
        <input
          type="radio"
          value="in last six months"
          {...register(fieldName)}
          disabled={disableCondition}
        />
        <label for="inline-2-radio" class="ms-2 text-sm font-medium">
          in last six months
        </label>
      </div>
      <div class="flex items-center me-4">
        <input
          type="radio"
          value="in last nine months"
          {...register(fieldName)}
          disabled={disableCondition}
        />
        <label for="inline-2-radio" class="ms-2 text-sm font-medium">
          in last nine months
        </label>
      </div>
      <div class="flex items-center me-4">
        <input
          type="radio"
          value="in last year"
          {...register(fieldName)}
          disabled={disableCondition}
        />
        <label for="inline-2-radio" class="ms-2 text-sm font-medium">
          in last year
        </label>
      </div>
    </>
  );
};
export default LastMeasuredSection;
