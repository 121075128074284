import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PathologySection from "../HealthSections/PathologySection";
import VetitinarySection from "../HealthSections/vetirinarySection";
import PediatricalSection from "../HealthSections/pediatricalSection";
import SpecificOrganSection from "../HealthSections/specificOrganSection";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { addPatientHealthProfile } from "../../Thunk/patientThunks";
import toast from "react-hot-toast";
import { useEffect } from "react";
import BloodMeasurementSection from "../HealthSections/BloodMeasurementSection";
import HeartRateMeasurement from "../HealthSections/HeartRateMeasurement";
import LastMeasuredSection from "../HealthSections/LastMeasuredSection/lastMeasuredSection";

const HealthForm = ({ disableCondition, isEditable, setIsHealthEditing,setHealthFieldDisable }) => {
  const { user } = useSelector((state) => state.auth);
  const { msg, error, patientData } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    values: {
      Age: patientData.Health_Records?.Age,
      Weight: patientData.Health_Records?.Weight,
      Height: patientData.Health_Records?.Height,
      Allergies: patientData.Health_Records?.Allergies,
      Vaccinations: patientData.Health_Records?.Vaccinations,
      medicationsTaken: patientData.Health_Records?.medicationsTaken,
      emergencyRoomVisit: patientData.Health_Records?.emergencyRoomVisit,
      bp_reading: patientData.Health_Records?.LastBP?.bp_reading,
      bp_last_measured: patientData.Health_Records?.LastBP?.bp_last_measured,
      hr_reading: patientData.Health_Records?.LastHeartRate?.hr_reading,
      hr_last_measured:
        patientData.Health_Records?.LastHeartRate?.hr_last_measured,
      surgicalIntervention: patientData.Health_Records?.surgicalIntervention,
      LastBP: patientData.Health_Records?.LastBP,
      LastHeartRate: patientData.Health_Records?.LastHeartRate,
      pathologicalSection: patientData.Health_Records?.PathologicalSection?.map(
        (item) => {
          return {
            Pathology_Descr: item.Pathology_Descr,
            CertifDate: item.CertifDate,
            Status: item.Status,
            CertifDoc: item.CertifDoc,
          };
        }
      ) || [{ Pathology_Descr: "", CertifDate: "", Status: "", CertifDoc: "" }],

      GeneralhealthInfo:
        patientData.Health_Records?.PediatricalSection?.GenhealthInfo,
      PediatricPatientHeight:
        patientData.Health_Records?.PediatricalSection?.Height,
      PediatricPatientWeight:
        patientData.Health_Records?.PediatricalSection?.Weight,
      PadeiatricDevelopmentalMilestones:
        patientData.Health_Records?.PediatricalSection?.DevelopmentalMilestones,
      organ_system:
        patientData.Health_Records?.SpecificOrganDiagnosis?.Organ_System,
      pathology_description:
        patientData.Health_Records?.SpecificOrganDiagnosis?.PathologyDescr,
      DtOfDiagnosis:
        patientData.Health_Records?.SpecificOrganDiagnosis?.DtOfDiagnosis,
      TreatmentHistory:
        patientData.Health_Records?.SpecificOrganDiagnosis?.TreatmentHistory,
      CurrentStat:
        patientData.Health_Records?.SpecificOrganDiagnosis?.CurrentStat,
      Animal_Species:
        patientData.Health_Records?.VetirinarySection?.Animal_Species,
      Breed: patientData.Health_Records?.VetirinarySection?.Breed,

      GenHealthInfo:
        patientData.Health_Records?.VetirinarySection?.GenhealthInfo,
      MedicalHistory:
        patientData.Health_Records?.VetirinarySection?.MedicalHistory,
      pediatricalVaccinationRecords:
        patientData.Health_Records?.PediatricalSection.Vaccination_Records.map(
          (item) => {
            return {
              vaccineName: item.vaccineName,
              vaccinationDate: item.vaccinationDate,
            };
          }
        ) || [{ vaccineName: "", vaccinationDate: "" }],
      vetirinaryVaccinationRecords:
        patientData.Health_Records?.VetirinarySection.Vaccination_Records.map(
          (itemx) => {
            return {
              vaccineName: itemx.vaccineName,
              vaccinationDate: itemx.vaccinationDate,
            };
          }
        ) || [{ vaccineName: "", vaccinationDate: "" }],
    },
  });
  useEffect(() => {
    if (msg) {
      toast.success(msg);
      reset();
    }
  }, [msg]);
  const handleAdd = async (data) => {
    const formdata = new FormData();
    const docArr = data.pathologicalSection.map((item, index) => {
      return patientData.Health_Records?.PathologicalSection[index]?.CertifDoc == item?.CertifDoc ? item?.CertifDoc : item?.CertifDoc[0];
    });

    docArr.forEach((file) => {
      formdata.append('CertifDoc',file)
    });
    formdata.append("PatientId", user.PatientId);
    formdata.append("Age", data.Age);
    formdata.append("Weight", data.Weight);
    formdata.append("Height", data.Height);
    formdata.append("Allergies", data.Allergies);
    formdata.append("Vaccinations", data.Vaccinations);
    formdata.append("medicationsTaken", data.medicationsTaken);
    formdata.append("emergencyRoomVisit", data.emergencyRoomVisit);
    formdata.append("surgicalIntervention", data.surgicalIntervention);
    formdata.append(
      "LastBP",
      JSON.stringify({
        bp_reading: data.bp_reading,
        bp_last_measured: data.bp_last_measured,
      })
    );
    formdata.append(
      "LastHeartRate",
      JSON.stringify({
        hr_reading: data.hr_reading,
        hr_last_measured: data.hr_last_measured,
      })
    );
    formdata.append(
      "PathologicalSection",
      JSON.stringify(
        data.pathologicalSection.map((item) => {
          return {
            Pathology_Descr: item.Pathology_Descr,
            CertifDate: item.CertifDate,
            Status: item.Status,
          };
        })
      )
    );
    formdata.append(
      "PediatricalSection",
      JSON.stringify({
        GenhealthInfo: data.GeneralhealthInfo,
        Height: data.PediatricPatientHeight,
        Weight: data.PediatricPatientWeight,
        DevelopmentalMilestones: data.PadeiatricDevelopmentalMilestones,
        Vaccination_Records: data.pediatricalVaccinationRecords,
      })
    );
    formdata.append(
      "VetirinarySection",
      JSON.stringify({
        Animal_Species: data.Animal_Species,
        Breed: data.Breed,
        GenhealthInfo: data.GenHealthInfo,
        Vaccination_Records: data.vetirinaryVaccinationRecords,
        MedicalHistory: data.MedicalHistory,
      })
    );
    formdata.append(
      "SpecificOrganDiagnosis",
      JSON.stringify({
        Organ_System: data.organ_system,
        PathologyDescr: data.pathology_description,
        DtOfDiagnosis: data.DtOfDiagnosis,
        TreatmentHistory: data.TreatmentHistory,
        CurrentStat: data.CurrentStat,
      })
    );
    formdata.append("isUpdated", true);

    dispatch(addPatientHealthProfile(formdata));
  };
  const {
    fields: pediatricalFields,
    append: appendPediatrical,
    remove: removePediatrical,
  } = useFieldArray({
    control,
    name: "pediatricalVaccinationRecords",
  });

  const {
    fields: pathologyFields,
    append: appendPathology,
    remove: removePathology,
  } = useFieldArray({
    control,
    name: "pathologicalSection",
  });

  const {
    fields: vetirinaryFields,
    append: appendVetirinary,
    remove: removeVetirinary,
  } = useFieldArray({
    control,
    name: "vetirinaryVaccinationRecords",
  });

  const addNewPathologyRecord = () => {
    appendPathology({
      Pathology_Descr: "",
      CertifDate: "",
      Status: "",
      CertifDoc: "",
    });
  };

  const removePathologyRecord = (index) => {
    removePathology(index);
  };

  const addNewPadeiatricRecord = () => {
    appendPediatrical({ vaccineName: "", vaccinationDate: "" });
  };
  const addNewVetirinaryRecord = () => {
    appendVetirinary({ vaccineName: "", vaccinationDate: "" });
  };
  const handleRemovePediatrical = (index) => {
    removePediatrical(index);
  };
  const handleRemoveVetirinary = (index) => {
    removeVetirinary(index);
  };
  return (
    <form onSubmit={handleSubmit(handleAdd)}>
      <div className="px-5">
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Age</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Age", { required: "Age is required" })}
              disabled={disableCondition}
            />
            {errors.Age && (
              <p className="text-red-500 text-xs mt-1">{errors.Age.message}</p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Weight</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Weight", { required: "Weight is required" })}
              disabled={disableCondition}
            />
            {errors.Weight && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Weight.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Height</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Height", { required: "Height is required" })}
              disabled={disableCondition}
            />
            {errors.Height && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Height.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Allergies</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Allergies", { required: "any alergies ??" })}
              disabled={disableCondition}
            />
            {errors.Allergies && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Allergies.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Vaccinations</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Vaccinations", {
                required: "Any Vaccinations Taken",
              })}
              disabled={disableCondition}
            />
            {errors.Vaccinations && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Vaccinations.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">
              Medications taken daily
            </label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("medicationsTaken", {
                required: "Daily Medications taken ??",
              })}
              disabled={disableCondition}
            />
            {errors.medicationsTaken && (
              <p className="text-red-500 text-xs mt-1">
                {errors.medicationsTaken.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <label className="text-sm mb-2 block">
              Previous visits to the emergency room
            </label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("emergencyRoomVisit", {
                required: "visited emergency room previously??",
              })}
              disabled={disableCondition}
            />
            {errors.emergencyRoomVisit && (
              <p className="text-red-500 text-xs mt-1">
                {errors.emergencyRoomVisit.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">
              Previous surgical interventions
            </label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("surgicalIntervention", {
                required: "Any previous surgical interventions",
              })}
              disabled={disableCondition}
            />
            {errors.surgicalIntervention && (
              <p className="text-red-500 text-xs mt-1">
                {errors.surgicalIntervention.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <label className="text-sm mb-2 block">
              Last blood pressure measurement
            </label>
            <Accordion sx={{ padding: "0px" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontSize="14px" fontWeight="bold">
                  Last BP measurement
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BloodMeasurementSection
                  register={register}
                  disableCondition={disableCondition}
                >
                  <LastMeasuredSection
                    register={register}
                    fieldName="bp_last_measured"
                    disableCondition={disableCondition}
                  />
                </BloodMeasurementSection>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <label className="text-sm mb-2 block">
              Last heart rate measurement
            </label>
            <Accordion sx={{ padding: "0px" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontSize="14px" fontWeight="bold">
                  Last Heart Rate measurement
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HeartRateMeasurement
                  register={register}
                  disableCondition={disableCondition}
                >
                  <LastMeasuredSection
                    register={register}
                    fieldName="hr_last_measured"
                    disableCondition={disableCondition}
                  />
                </HeartRateMeasurement>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight="bold">Pathologies</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PathologySection
                  register={register}
                  fields={pathologyFields}
                  isEditable={isEditable}
                  onAddHandler={addNewPathologyRecord}
                  onRemoveHandler={removePathologyRecord}
                  disableCondition={disableCondition}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight="bold">Pediatrical Section</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PediatricalSection
                  register={register}
                  fields={pediatricalFields}
                  onAddHandler={addNewPadeiatricRecord}
                  onRemoveHandler={handleRemovePediatrical}
                  disableCondition={disableCondition}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight="bold">Vetirinary Section</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VetitinarySection
                  register={register}
                  fields={vetirinaryFields}
                  onAddHandler={addNewVetirinaryRecord}
                  onRemoveHandler={handleRemoveVetirinary}
                  disableCondition={disableCondition}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight="bold">
                  Specific Organ Section
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SpecificOrganSection
                  register={register}
                  disableCondition={disableCondition}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
      {!patientData?.Health_Records?.isUpdated && (
        <div className="flex gap-3 mt-4 justify-end border-t-2 pt-4 border-slate-300 px-5">
          <button
            type="button"
            className="rounded-full py-3 px-12 bg-red-200 text-sm text-red-700 hover:bg-red-500 hover:text-white transition-all delay-300"
          >
            Reset
          </button>
          <button
            type="submit"
            className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
          >
            Save
          </button>
        </div>
      )}
      {isEditable == true && !disableCondition && (
        <div className="flex gap-3 mt-4 justify-end border-t-2 pt-4 border-slate-300 px-5">
          <button
            type="button"
            onClick={() => {setHealthFieldDisable(true); setIsHealthEditing(false)}}
            className="rounded-full py-3 px-12 bg-red-200 text-sm text-red-700 hover:bg-red-500 hover:text-white transition-all delay-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
          >
            Update
          </button>
        </div>
      )}
    </form>
  );
};
export default HealthForm;
