import React from 'react'

export default function Breadcumb(props) {
  return (
    <>
    <section className="innerbanner position-relative">
        <video width="100%" height="400" autoPlay muted loop>
        <source src="assets/videos/covidbannervideo.mp4" type="video/mp4"/>
        </video>
        <div className="pagebannertext text-center">
            <h2>{props.pagetitle}</h2>
        </div>
    </section>
    </>
  )
}
