import { Rating } from "@mui/material";
import { Constants } from "../../Constants";
import { GetReviewStarRating } from "../../Utils/getReviewStarRating";

export const DrCheckOutInfo = ({ data }) => {
  return (
    <div className="border rounded-xl p-5 bg-white shadow-lg mb-6">
      <img
        src={`${Constants.getImg}${data.profilePic}`}
        className="w-48 h-48 rounded-full border-2 cover mx-auto"
      />
      <h2 className="text-2xl font-bold my-2 mt-5">{data.Name}</h2>
      <p className="my-1">{data.ProfessionalInformation.Degree}</p>
      <p className="my-1">
        {data.ProfessionalInformation.work_experience} years experience
      </p>
      <p className="my-1">
        $ {data.Fares.clinic_fare} Consultation fee at clinic
      </p>
      <p className="my-1">
        <i className="fa-solid text-orange-800 fa-location-dot"></i>{" "}
        {data.Address}
      </p>
      {data.Ratings?.length > 0 && (
        <div className="flex items-center mt-3 text-sm">
          <Rating
            size="small"
            defaultValue={GetReviewStarRating(data.Ratings)}
            precision={0.5}
            readOnly
          />
          <div className="ml-3 rounded-full bg-indigo-800 px-2 text-white text-xs py-0">
            {GetReviewStarRating(data.Ratings)}
          </div>
        </div>
      )}
    </div>
  );
};
