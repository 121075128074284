import { Rating } from "@mui/material";
import React from "react";
import { getInitials } from "../../Utils/getNameInitials";
import moment from 'moment'
export default function ReviewList({ ratings }) {
  return (
    <>
      <div className="mb-6">
        <h5 className="text-xs text-gray-500 font-semibold">
          {moment(ratings.reviewDateTime).format("DD MMM YYYY")}
        </h5>
         <Rating size="large" defaultValue={ratings.stars} precision={0.5} readOnly />
        <div className="flex my-2 items-center">
          <div className="w-9 h-9 bg-violet-200 text-center p-2 rounded-full text-sm text-violet-600 font-semibold mr-3">
            {getInitials(ratings.reviewUser)}
          </div>
          <h3 className="font-semibold">{ratings.reviewUser}</h3>
        </div>
        <p className="text-sm">{ratings.reviewDescr}</p>
      </div>
    </>
  );
}
