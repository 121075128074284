import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/colors/green.css';
import './Datepicker.css'

const DateSpecificHours = ({selectedDates, setSelectedDates, timeRanges, setTimeRanges}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleAddTimeRange = () => {
    setTimeRanges([...timeRanges, { start: '', end: '' }]);
  };

  const handleRemoveTimeRange = (index) => {

    const updatedTimes = timeRanges.filter((_, i) => i !== index);
    setTimeRanges(updatedTimes);
  };

  const handleTimeChange = (index, field, value) => {

    const updatedTimes = timeRanges.map((time, i) =>
      i === index ? { ...time, [field]: value } : time
    );
    setTimeRanges(updatedTimes);
  };

  return (
    <div className='md:flex gap-10'>
      <div className="date-picker-section mb-5">
        <label className='block mb-2'>Select the date(s) you want to assign specific hours:</label>
        <DatePicker
            multiple
            value={selectedDates}
            onChange={setSelectedDates}
            onClose={() => setIsOpen(false)}
            format="YYYY-MM-DD"
         />
      </div>
      <div className="time-ranges-section mb-5">
        <label className='block mb-2'>What hours are you available?</label>
        {timeRanges.map((time, index) => (
          <div key={index} className="time-range mb-1">
            <input className='px-2 py-2 bg-white border rounded text-sm border-gray-400'
              type="time"
              value={time.start}
              onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
              placeholder="HH:MM"
              required
            />
            <span> - </span>
            <input className='px-2 py-2 bg-white border rounded text-sm border-gray-400'
              type="time"
              value={time.end}
              onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
              placeholder="HH:MM"
              required
            />
            <button type='button' className='h-6 text-white rounded-full w-6 bg-red-500 inline-block hover:bg-red-600 ml-1' onClick={() => handleRemoveTimeRange(index)}>✕</button>
          </div>
        ))}
        <button type='button' className='py-2 px-2 text-xs rounded  text-white bg-green-400 hover:bg-green-500' onClick={handleAddTimeRange}>+ Add More</button>
      </div>
    </div>
  );
};

export default DateSpecificHours;
