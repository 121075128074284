// src/Accordion.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
const FaqAccordian = () => {
  const { faqList } = useSelector((state) => state.faq);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full p-4 faqaccordian bg-white">
      {faqList.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.Ques}
          content={item.Answer}
          isOpen={openIndex === index}
          onClick={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};
const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <div className="border-b border-gray-200">
    <button
      className="w-full px-3 py-4 text-black text-left focus:outline-none flex justify-between items-center"
      onClick={onClick}
    >
      <span className="text-md font-semibold text-gray-700">{title}</span>
      <svg
        className={`h-5 w-5 transform transition-transform duration-200 ${
          isOpen ? "rotate-180" : ""
        }`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
    {isOpen && (
      <div
        className="px-3 pt-2 pb-4 text-md text-gray-600"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    )}
  </div>
);

export default FaqAccordian;
