import { Grid } from '@mui/material'
import React from 'react'

export default function MedicalHealthBlock() {
  return (
    <>
    <section className='py-28 relative bg-fixed' style={{backgroundImage: 'url(../assets/images/getin-bg.jpg)'}}>
        <div className='absolute top-0 left-0 w-full h-full' style={{backgroundColor:'#00000060'}}></div>
        <div className='container mx-auto px-4 relative z-10'>
            <Grid container columns={12} spacing={3}>
                <Grid item lg={6} md={12}>
                    <p className='text-white mb-0'>Get In Touch</p>
                    <span className='inline-block w-7 h-0.5 bg-white'></span>
                    <h3 className='text-white lg:text-5xl text-2xl font-semibold my-7'>Best Medical & Health Care Near Your City</h3>
                    <p className='text-white mb-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid idunt ut labore et dolore.</p>
                    <div className='flex gap-3'>
                        <a href='#' className='border-2 border-blue-700 bg-primary text-white px-3 lg:px-7 py-3 rounded-full transition-all duration-300 hover:bg-blue-900 '>Get Appointment</a>
                        <a href='#' className='border-2 text-white px-3 lg:px-7 py-3 rounded-full transition-all duration-300 hover:bg-white hover:text-black '>Explore More</a>
                    </div>
                </Grid>
            </Grid>
        </div>
    </section>
    </>
  )
}
