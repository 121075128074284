import React from 'react'
import {Bannersearch} from './Bannersearch'

export default function Homebanner() {
  return (
    <>
    <section className="bannerhome relative">
        <div className="bannerimg">
          <img src="assets/images/banner1.png"/>
        </div>
        <div className=" bannercontent w-full px-4">
          <div className="container m-auto">
            <h1 className="text-white font-bold mb-4">Consult Best Doctors <br/>
            Your Nearby Location</h1>
            <h5 className="text-white text-lg mb-8">Discover the best doctors, clinic & hospital the city nearest to you.</h5>
            <a href="" className="bg-primary text-white rounded-full px-9 py-3 text-lg inline-block transition-all duration-300 hover:bg-blue-700">Start a Consult</a>
            <Bannersearch />
          </div>
        </div>
    </section>
    </>
  )
}
