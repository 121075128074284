import React, { useState, useEffect } from "react";
import BannerDashboard from "./BannerDashboard";
import WeeklyAvailability from "../Availability/WeeklyAvailability";
import DateSpecificHours from "../Availability/DateSpecificHours";
import Modal from "../Modal/Modal";
import { Grid } from "@mui/material";
import ToggleNavs from "../ToggleNavs/toggleNavs";
import TimezoneDropdown from "../TimeZoneDropdown/timeZoneDropdown";
import moment from "moment-timezone";
import {
  AddDoctorAvailability,
  GetDoctorAvailabilities,
} from "../../Thunk/doctorThunks";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {clearMsg } from "../../Actions/Doctor/doctorActions";
export default function DrAvailability() {
  const { msg} = useSelector(
    (state) => state.doctor
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [selectedDates, setSelectedDates] = useState([]);
  const [timeRanges, setTimeRanges] = useState([
    { start: "09:00", end: "10:00" },
  ]);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [availability, setAvailability] = useState({
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: [],
  });

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      dispatch(clearMsg());
    }
  }, [msg]);

  useEffect(() => {
    dispatch(GetDoctorAvailabilities(user.Id)).then((item) => {
      if (item) {
        setTimezone(item.Timezone);
        setAvailability(item.WeeklyHours);
        setSelectedDates(item.DateSpecificHours?.flatMap(
          (itx) => itx.date
        ));
        setTimeRanges(item.DateSpecificHours?.flatMap(
          (itx) => itx.timeRanges
        ));
      }
    });
  }, [msg]);

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleDoctorAvailability = () => {
    const dateSpecificHours = [
      {
        date: selectedDates,
        timeRanges: timeRanges,
      },
    ];
    const userData = {
      DoctorId: user.Id,
      Availability: {
        Timezone: timezone,
        WeeklyHours: availability,
        DateSpecificHours: dateSpecificHours,
      },
    };
    dispatch(AddDoctorAvailability(userData));
  };
  return (
    <>
      <BannerDashboard pageTitle="Availability" />
      <section className="py-8">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h2 class="text-2xl font-semibold  pb-3 mb-6 border-b">
                Availability
              </h2>
              <div className="md:p-6 md:shadow-lg md:border lg:rounded-lg">
                <Grid container columns={12} spacing={4}>
                  <Grid item lg={5} xs={12}>
                    <div className="mb-5">
                      <TimezoneDropdown
                        timezone={timezone}
                        handleTimezoneChange={handleTimezoneChange}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={4}
                  className="justify-between"
                >
                  <Grid item lg={5} xs={12}>
                    <h2 className="mb-5 font-semibold text-lg">Weekly Hours</h2>
                    <WeeklyAvailability
                      availability={availability}
                      setAvailability={setAvailability}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <h2 className="mb-5 font-semibold text-lg">
                      Date-Specific Hours
                    </h2>
                    <p className="text-sm mb-5">
                      Override your availability for specific dates when your
                      hours differ from your regular weekly hours.
                    </p>
                    <button
                      className=" bg-primary rounded-lg text-white font-semibold py-3 px-8 hover:bg-black transition-all delay-200 text-sm"
                      type="button"
                      onClick={openModal}
                    >
                      Add Date-Specific Hours{" "}
                    </button>

                    <div className="mt-10">
                      {selectedDates.map((date) => (
                        <div className="flex mb-1 bg-gray-100 py-2 px-2 md:px-3 rounded-lg gap-4 justify-between text-sm items-center border">
                          <div className="flex gap-4 w-full justify-between">
                            <p className="font-semibold">
                              {new Date(date).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              })}
                            </p>
                            <p>
                              <span className="text-xs inline-block py-1 px-3 bg-green-200 rounded text-green-800 font-semibold">
                                {timeRanges.map((time, index) => (
                                  <span>
                                    {moment(time.start, "HH:mm").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(time.end, "HH:mm").format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                ))}
                              </span>
                            </p>
                          </div>

                          <div>
                            <button className="h-6 text-white rounded-full w-6 bg-red-500 inline-block hover:bg-red-600 ml-1 text-sm">
                              ✕
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
                <div className="mt-6 text-right">
                  <button
                    type="button"
                    onClick={handleDoctorAvailability}
                    className=" bg-primary rounded-lg text-white font-semibold py-3 px-8 hover:bg-black transition-all delay-200 text-sm"
                  >
                    Save{" "}
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="flex justify-between gap-3 items-center mb-2">
          <h2 className="md:text-2xl text-xl mb-4 font-semibold capitalize">
            Date-specific hours
          </h2>
          <button
            className="bg-red-500 text-white w-8 h-8 rounded-full mb-4"
            onClick={closeModal}
          >
            {" "}
            <i class="fa-sharp fa-regular fa-xmark"></i>{" "}
          </button>
        </div>
        <div className="mb-5 max-h-96 overflow-x-auto">
          <DateSpecificHours
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
          />
        </div>
        <div className="flex gap-2 justify-center">
          <button
            className=" bg-gray-200 rounded-lg text-gray-700 font-semibold py-3 px-8 hover:bg-gray-300 transition-all delay-200 text-sm"
            type="button"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className=" bg-primary rounded-lg text-white font-semibold py-3 px-8 hover:bg-black transition-all delay-200 text-sm"
            type="button"
            onClick={closeModal}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
}
