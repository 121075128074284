import React, { useEffect } from "react";
import BannerDashboard from "./BannerDashboard";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { changePassword, Logout } from "../../Thunk/authThunks";
import { useSelector, useDispatch  } from "react-redux";
import { useNavigate} from "react-router-dom";
import ToggleNavs from "../ToggleNavs/toggleNavs";
export default function ChangePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { user, msg,error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChangePassword = (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Password does not match");
    } else {
      const requestBody = {
        newPassword: data.password,
        userId: user.Id,
      };
      dispatch(changePassword(requestBody));
    }
  };

  useEffect(() => {
    if(msg === 'Password Changed Successfully'){
        toast.success(msg);
        reset();
        setTimeout(() => {
            dispatch(Logout());
            navigate('/')
        },2000)
    }
    if(error){
        toast.error('Something went wrong!!');
    }
  },[msg])
  return (
    <>
      <BannerDashboard pageTitle="Change Password" />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h2 className="text-2xl font-semibold  pb-3 mb-6 border-b">
                Change Password
              </h2>
              <div className="border rounded-lg shadow-lg p-6 bg-white">
                <Grid container columns={12} spacing={4}>
                  <Grid item lg={6}>
                    <form onSubmit={handleSubmit(handleChangePassword)}>
                      <div className="mb-4">
                        <label className="mb-2 block font-semibold">
                          New Password
                        </label>
                        <input
                          type="password"
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be at least 8 characters long",
                            },
                            maxLength: {
                              value: 15,
                              message: "Password must not exceed 15 characters",
                            },
                          })}
                          className="border w-full py-4 px-4 rounded-lg text-sm border-gray-400"
                          placeholder="Enter New Password"
                        />
                        {errors.password && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="mb-2 block font-semibold">
                          Re-enter new password
                        </label>
                        <input
                          type="password"
                          className="border w-full py-4 px-4 rounded-lg text-sm border-gray-400"
                          placeholder="Re-enter new password"
                          {...register("confirmPassword", {
                            required: "Confirm Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be at least 8 characters long",
                            },
                            maxLength: {
                              value: 15,
                              message: "Password must not exceed 15 characters",
                            },
                          })}
                        />
                        {errors.confirmPassword && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.confirmPassword.message}
                          </p>
                        )}
                      </div>
                      <div class="mb-4">
                        <button class=" bg-primary rounded-lg text-white font-semibold py-4 px-8 hover:bg-black transition-all delay-200">
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
