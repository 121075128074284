import React from 'react'
import Slider from 'react-slick'

export default function Awards() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow:6,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000, 
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow:3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };
  return (
    <>
         <section className='py-12 px-4 awardspnl'>
            <div className='container mx-auto '>
                <div className='flex items-center justify-center mb-16'>
                    <h2 className='font-bold lg:text-4xl pagetitle relative text-2xl text-center'>Recent Awards</h2>
                </div>
                <div className='awardSlide px-3'>
                  <Slider {...settings}>
                    <div className='awardLogo'>
                      <img src='assets/images/award-1.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-2.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-3.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-4.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-1.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-2.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-3.png'/>
                    </div>
                    <div className='awardLogo'>
                      <img src='assets/images/award-4.png'/>
                    </div>
                  </Slider>
                </div>
                
            </div>
        </section>
    </>
  )
}
