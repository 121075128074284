import React from "react";
import {Bannersearch} from "../Home/Bannersearch";
export default function InnerBanner() {
  return (
    <>
      <section
        className="bg-gray-200 bg-cover relative"
        style={{ backgroundImage: "url(../assets/images/inner-banner.png)" }}
      >
        <div
          className="absolute w-full h-full left-0 top-0 z-10"
          style={{ backgroundColor: "#00000060" }}
        ></div>
        <div className="container  mx-auto relative z-20">
          <div className=" md:flex items-center py-4 justify-center">
            <Bannersearch />
          </div>
        </div>
      </section>
    </>
  );
}
