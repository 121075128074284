import React, { useEffect, useState } from "react";
import InnerBanner from "../InnerBannersearch/InnerBannersearch";
import { Grid } from "@mui/material";
import Drinfo from "./Drinfo";
import DrinfoTab from "./DrinfoTab";
import Bookingcalender from "./Bookingcalender";
import Clinicinfo from "./Clinicinfo";
import { useLocation } from "react-router-dom";
import VideoConsult from "./videoConsultation";
import { DisableSlots } from "../../Utils/disableSlots";
import toast from "react-hot-toast";
export default function DoctorDetails() {
  const location = useLocation();
  const { state } = location;
  const [drDetails, setDrDetails] = useState(state);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [date, setDate] = useState(new Date());
  const isSlotOver = DisableSlots(date,selectedSlot)
  useEffect(() => {
     if(isSlotOver) {
      toast.error('Slot Over')
     }
  },[selectedSlot])
  return (
    <>
      <InnerBanner />
      <section className="container mx-auto px-4 py-10 bg-gray-50">
        <Grid container columns={12} spacing={4}>
          <Grid item lg={8} md={12}>
            <Drinfo data={drDetails.details} />
            <div className="mt-5">
              <DrinfoTab data={drDetails.details} />
            </div>
          </Grid>
          <Grid item lg={4} md={12}>
            <h2 className="mb-5 text-xl font-semibold">Pick a time slot</h2>
            <Bookingcalender
              data={drDetails.details.Availability}
              date={date}
              setDate={setDate}
              disabled={isSlotOver}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
            />
            
            {date && selectedSlot !== "" && isSlotOver === false && (
              <>
                <VideoConsult
                  data={drDetails.details}
                  date={date}
                  slot={selectedSlot}
                />
                <Clinicinfo
                  data={drDetails.details}
                  date={date}
                  slot={selectedSlot}
                />
              </>
            )}
          </Grid>
        </Grid>
      </section>
    </>
  );
}
