import axios from "axios";
import { Constants } from "../Constants";
import {
  addBlogComment,
  addBlogLikes,
  createBlogPost,
  deleteBlogPost,
  fetchBlogPost,
  fetchBlogPostByBlogId,
  fetchBlogPostByUser,
  removeBlogLikes,
} from "../Actions/Blogs/blogAction";

export const FetchAllBlogPosts = () => async (dispatch) => {
  try {
    const response = await axios.get(`${Constants.fetchBlogPosts}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(fetchBlogPost(response.data));
  } catch (error) {
    return error;
  }
};

export const CreateBlogPosts = (userData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${Constants.createBlogPosts}`,
      userData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(createBlogPost(response.data));
  } catch (error) {
    return error;
  }
};

export const FetchBlogPostsByUser = (userData) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${Constants.fetchBlogPostsByUser}${userData.Id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(fetchBlogPostByUser(response.data));
  } catch (error) {
    return error;
  }
};

export const FetchBlogPostsByBlogId = (userData) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${Constants.fetchBlogPostsByBlogID}${userData}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(fetchBlogPostByBlogId(response.data));
  } catch (error) {
    return error;
  }
};

export const AddBlogComment = (userData) => async(dispatch) => {
  try {
    const response = await axios.post(
      `${Constants.addBlogComment}`,userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(addBlogComment(response.data));
  } catch (error) {
    return error;
  }
}

export const LikeBlog = (userData) => async(dispatch) => {
  try {

    const response = await axios.post(
      `${Constants.addBlogLike}`,userData,
      {
        headers: {
          "Content-Type": "application/json",
         
        },
      }
    );
    dispatch(addBlogLikes(response.data));
  } catch (error) {
    return error;
  }
}
export const UnlikeBlog = (userData) => async(dispatch) => {
  try {
 
    const response = await axios.post(
      `${Constants.removeBlogLike}`,userData,
      {
        headers: {
          "Content-Type": "application/json",
 
        },
      }
    );
    dispatch(removeBlogLikes(response.data));
  } catch (error) {
    return error;
  }
}

export const DeleteBlogPost = (userData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${Constants.deleteBlogPost}${userData.Id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(deleteBlogPost(response.data));
  } catch (error) {
    return error;
  }
};
