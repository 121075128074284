import React, { useState } from 'react';
import DrCard from './DrCard';
import { useSelector } from 'react-redux';

const DrTab = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { doctorLists } = useSelector((state) => state.doctor);
    const [selectedHeart, setSelectedHeart] = useState(JSON.parse(localStorage.getItem("userInfo"))?.Favourites);
    const specializations = [
        'All Doctors',
        'Endocrinologist',
        'Pediatrician',
        'Gynecologist',
        'Dermatologist',
        'Physicians',
        'Oncologist',
        'Cardiologist',
        'Neurologist',
        'Dentist',
     
    ];
    const getFilteredDoctors = () => {
        const specialization = specializations[activeTab];
        if (specialization === 'All Doctors') {
            return doctorLists;
        }
        return doctorLists.filter((doctor) =>
            doctor.ProfessionalInformation.Specializations.includes(specialization)
        );
    };

    const filteredDoctors = getFilteredDoctors();

    return (
        <div className="w-full">
            <div className="lg:flex border-gray-200">
                {specializations.map((spec, index) => (
                    <button
                        key={index}
                        className={`px-5 bg-gray-200 rounded-full py-2 text-sm font-medium focus:outline-none m-1 ${activeTab === index
                            ? ' text-white bg-primary'
                            : ' text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab(index)}
                    >
                        {spec}
                    </button>
                ))}
            </div>
            <div className="py-4">
                <DrCard doctorLists={filteredDoctors} selectedHeart={selectedHeart} setSelectedHeart={setSelectedHeart} />
            </div>
        </div>
    );
};

export default DrTab;
