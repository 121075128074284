import {ADD_DOCTOR_DATA, SET_ERROR, CLEAR_ERROR, CLEAR_MSG,  GET_DOCTOR_PROFILE, ADD_DOCTOR_AVAILABILITY, GET_DOCTOR_AVAILABILITIES, GET_DOCTOR_SPECIALITITY_INFO, GET_ALL_DOCTOR_LIST, ADD_DOCTOR_REVIEWS, FETCH_ALL_UPCOMING_APPOINTMENTS } from './types';



export const addDoctorData = (apiPayload) => ({
  type: ADD_DOCTOR_DATA,
  payload: apiPayload,
});

export const addDoctorReviews = (apiPayload) => ({
  type:ADD_DOCTOR_REVIEWS,
  payload:apiPayload
})

export const getDoctorProfile = (apiPayload) => ({
  type: GET_DOCTOR_PROFILE,
  payload: apiPayload,
});

export const addDoctorAvailability = (apiPayload) => ({
  type: ADD_DOCTOR_AVAILABILITY,
  payload: apiPayload,
});

export const fetchAllUpcomingAppointments = (apiPayload) => ({
  type: FETCH_ALL_UPCOMING_APPOINTMENTS,
  payload: apiPayload
})

export const getDoctorAvailabilities = (apiPayload) => ({
  type: GET_DOCTOR_AVAILABILITIES,
  payload: apiPayload,
});

export const getDoctorSpecialityInfo = (apiPayload) => ({
   type: GET_DOCTOR_SPECIALITITY_INFO,
   payload: apiPayload
})

export const getAllDoctorLists = (apiPayload) => ({
  type: GET_ALL_DOCTOR_LIST,
  payload: apiPayload
})

export const setError = (err) => ({
  type: SET_ERROR,
  payload: err,
});
export const clearError = () => ({
  type: CLEAR_ERROR,
});
export const clearMsg = () => ({
  type: CLEAR_MSG,
});

