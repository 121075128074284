export const ADD_PATIENT_DATA = 'ADD_PATIENT_DATA';
export const ADD_PATIENT_HEALTH_RECORD = 'ADD_PATIENT_HEALTH_RECORD';
export const ADD_PATIENT_MEDICAL_RECORD = 'ADD_PATIENT_MEDICAL_RECORD';
export const FETCH_PATIENT_MEDICAL_RECORD = 'FETCH_PATIENT_MEDICAL_RECORD';
export const EDIT_PATIENT_MEDICAL_RECORD = 'EDIT_PATIENT_MEDICAL_RECORD';
export const DELETE_PATIENT_MEDICAL_RECORD = 'DELETE_PATIENT_MEDICAL_RECORD';
export const UPDATE_PATIENT_PERSONAL_DATA = 'UPDATE_PATIENT_PERSONAL_DATA';
export const ADD_TO_FAVOURITES = 'ADD_TO_FAVOURITES';
export const REMOVE_FROM_FAVOURITES = 'REMOVE_FROM_FAVOURITES';
export const BOOK_CLINIC_APPOINTMENT = 'BOOK_CLINIC_APPOINTMENT';
export const FETCH_ALL_APPOINTMENTS = 'FETCH_ALL_APPOINTMENTS';
export const GET_PATIENT_PROFILE = 'GET_PATIENT_PROFILE';
export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_MSG = 'CLEAR_MSG';