import React from 'react'
import Innerbanner from '../InnerBanner/Innerbanner'
import { Grid } from '@mui/material'
import CheckoutTable from './CheckoutTable'
import { useLocation } from 'react-router-dom'
import { DrCheckOutInfo } from '../DoctorDetails/DrCheckOutInfo'
import { AppointmentDetails } from '../AppointmentDetails/appointmentDetails'

export default function Checkout() {
    const location = useLocation();
    const {state} = location;
  return (
    <>
        <Innerbanner pageTitle="Checkout"/>
        <section className='container mx-auto px-4 py-10'>
            <Grid container columns={12} spacing={4}>
                <Grid item lg={3} md={5} sm={12} xs={12}>
                   <DrCheckOutInfo data={state} />
                    <AppointmentDetails data={state} />
                </Grid>
                <Grid item lg={9} md={7} sm={12} xs={12}>
                    <h2 className='font-semibold text-2xl mb-5'>Your Price summary</h2>
                    <CheckoutTable data={state} />
                </Grid>
            </Grid>
        </section>
    </>
  )
}
