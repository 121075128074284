import moment from "moment";
export const DisableCalendarTile = (slots) => {
    const currentTime = moment(); 
    for (const slot of slots) {
        const [startTimeStr, endTimeStr] = slot.split(" - ");
        const startTime = moment(startTimeStr, "hh:mm A");
        const endTime = moment(endTimeStr, "hh:mm A");
        if (currentTime.isBetween(startTime, endTime, null, '[]')) {
            return false;
        }
    }
    return true;
}