import React from "react";
import Navbarheader from "./Navbarheader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Header(props) {
  return (
    <>
      <Navbarheader />
    </>
  );
}
