import { ADD_BLOG_COMMENT, CLEAR_MSG, CREATE_BLOG_POSTS, DELETE_BLOG_POSTS, FETCH_BLOG_BYBLOGID, FETCH_BLOG_BYID, FETCH_BLOG_POSTS, LIKE_BLOG, UNLIKE_BLOG} from './types';

export  const fetchBlogPost = (payLoadData) => ({
  type: FETCH_BLOG_POSTS, 
  payload: payLoadData,
});

export  const createBlogPost = (payLoadData) => ({
  type: CREATE_BLOG_POSTS, 
  payload: payLoadData,
});
export const fetchBlogPostByUser = (payLoadData) => ({
  type: FETCH_BLOG_BYID, 
  payload: payLoadData,
})
export const fetchBlogPostByBlogId = (payLoadData) => ({
  type: FETCH_BLOG_BYBLOGID, 
  payload: payLoadData,
})

export const addBlogComment = (payLoadData) => ({
  type: ADD_BLOG_COMMENT,
  payload:payLoadData,
})

export const addBlogLikes = (payLoadData) => ({
  type: LIKE_BLOG,
  payload: payLoadData
})

export const removeBlogLikes = (payLoadData) => ({
  type: UNLIKE_BLOG,
  payload:payLoadData
})

export const deleteBlogPost = (payLoadData) => ({
  type: DELETE_BLOG_POSTS, 
  payload: payLoadData,
})
export const clearMsg = () => ({
  type: CLEAR_MSG,
});


