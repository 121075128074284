import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearMsg } from "../../Actions/Auth/authActions";
import { ForgetPassword } from "../../Thunk/authThunks";
import toast from "react-hot-toast";
export default function Forgotpassword() {
  const { msg } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      setValue('email','')
      setTimeout(() => {
        dispatch(clearMsg());  
      }, 1300);
    }
  }, [msg]);

  const handleReset = (data) => {
    console.log(data);
    dispatch(ForgetPassword({ email: data.email }));
  };
  return (
    <>
      <section className="py-20 border-t-2 bg-slate-50">
        <div className="container mx-auto px-4">
        
            <Grid container columns={12} className="justify-center">
              <Grid item xs={12} md={8} lg={6} xl={5}>
                <div className="p-9 bg-white formsignin shadow-lg">
                  <h2 className="lg:text-3xl md:text-2xl text-2xl font-bold mb-6">
                    Forgot Password
                  </h2>
                  <div className="mb-4">
                    <label> Email</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className={`border-2 w-full py-4 px-4 rounded-lg text-sm ${
                          errors.email ? "border-red-500" : "border-black"
                        }`}
                        placeholder="Enter your email address"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[a-z0-9._-]+@[a-z]+\.[a-z]{2,3}$/,
                            message: "Invalid email format",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="mb-4">
                    <button
                      type="button"
                      class="w-full bg-primary rounded-lg text-white font-semibold py-4 px-4 hover:bg-black transition-all delay-200"
                      onClick={handleSubmit(handleReset)}
                    >
                      Continue
                    </button>
                  </div>
                  <div className="mb-4">
                    <p>
                      Remembered your password?{" "}
                      <a href="/signin" className="font-bold text-primary">
                        Sign In
                      </a>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
      
        </div>
      </section>
    </>
  );
}
