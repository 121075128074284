import React from 'react'
import { Grid } from '@mui/material';

export default function LeaderList() {
    const LeaderList = [
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
        {title: 'Jonson Calrs', position:'Medical Assistant', drimg:'assets/images/dr1.jpg'},
      ]
      const leaders = LeaderList.map(leaders =>
        
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <div key={leaders.id} className='text-center'>
                    <div className='drBlock mb-5'>
                       <a href='#'><img src={leaders.drimg} className='lg:h-60 lg:w-60 md:h-48 md:w-48 w-32 h-32 rounded-full lg:p-4 p-3 cover mx-auto bg-gray-100 '/></a> 
                    </div>
                    <h3 className='lg:text-2xl text-lg font-semibold mb-1'><a href='#'>{leaders.title}</a></h3>
                    <h4 className='lg:text-lg text-sm mb-2'>{leaders.position}</h4>
                    <div className='flex gap-3 justify-center text-2xl mb-4'>
                        <a href='#' style={{color: '#1877F2'}}><i class="fa-brands fa-facebook"></i></a>
                        <a href='#' style={{color: '#0077B5'}}><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
            </Grid>
        
      );
  return (
    <>
        <Grid container  columns={12} spacing={3} alignItems="center">
            {leaders}
        </Grid>
    </>
  )
}
