import { Grid } from '@mui/material'
import React from 'react'
import LeaderList from './LeaderList'

export default function Leadership() {
  return (
    <>
        <section className='pt-32 pb-5 h-screen bg-cover relative bg-center' style={{backgroundImage: 'url(../assets/images/leaderbanner.png)'}}>
            <div className='absolute top-0 left-0 w-full h-full' style={{backgroundColor:'#00000050'}}></div>
            <div className='absolute bottom-0 pb-28 left-0 w-full z-10'>
                <div className='container mx-auto px-4'>
                    <Grid container columns={12} spacing={4} className='justify-center'>
                        <Grid item lg={5} className='text-center'>
                            <h2 className='font-bold lg:text-4xl relative text-2xl mb-12 text-white'>Help more people find their healthier ever after</h2>
                            <a href='#' className='border-2 border-blue-700 bg-primary text-white px-3 lg:px-7 py-3 rounded-full transition-all duration-300 hover:bg-blue-900 '>Get Appointment</a>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>
        <section className='py-16'>
            <div className='container mx-auto px-4'>
                <Grid container columns={12} spacing={4} className='justify-center mb-10'>
                    <Grid item lg={5} className='text-center'>
                        <h2 className='font-bold lg:text-4xl relative text-2xl mb-3 text-black text-center'>Quality care for life Your health our priority</h2>
                        <span className='bg-primary h-1.5 w-16 inline-block'></span>
                    </Grid>
                </Grid>
                <LeaderList/>
            </div>
        </section>
    </>
  )
}
