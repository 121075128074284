import { Grid } from '@mui/material'
import React from 'react'

export default function ServicesCard() {
    const servicesBoxlist = [
        {title: 'Experience Doctor', desc: 'Our products are certified by reputable organic.', icon: 'assets/images/s1.png'},
        {title: 'Painless Treatment', desc: 'Our products are certified by reputable organic.', icon: 'assets/images/s2.png'},
        {title: 'Top Equipment', desc: 'Our products are certified by reputable organic.', icon: 'assets/images/s3.png'},
        {title: '24/7 Advance Care', desc: 'Our products are certified by reputable organic.', icon: 'assets/images/s4.png'}
    ]
    const servicesBox = servicesBoxlist.map(servicesBox =>
        <Grid item lg={3} md={6} xs={6}>
            <div key={servicesBox.id} className='expServiceBlock text-center shadow-lg border rounded-2xl py-9 lg:px-10 px-4 bg-cover hover:-translate-y-2 transition-all hover:shadow-2xl' style={{ backgroundImage: 'url(../assets/images/serviceBg.png)', }}>
                <div className='mb-5'>
                    <span className='mx-auto w-16 h-16 bg-gray-200 block rounded-full'></span>
                    <img src={servicesBox.icon} className='mx-auto h-12 contain' style={{marginTop: '-25px'}}/>
                </div>
                <h2 className='text-xl font-semibold mb-2'>{servicesBox.title}</h2>
                <p className='mb-0 text-gray-500'>{servicesBox.desc}</p>
            </div>
        </Grid>
        
    );
  return (
    <>
        <Grid container columns={12} spacing={3} >
            {servicesBox}
        </Grid>
    </>
  )
}
