import { FETCH_ALL_FAQS } from "../Actions/FAQ/types";

  
  const initialState = {
    faqList: [],
  };
  
  const FaqReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_FAQS:
        return {
          ...state,
          faqList: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default FaqReducer;
  