import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const { user, token } = useSelector((state) => state.auth);

  return user && token && user.userType === "Patient" ? (
    children
  ) : (
    <Navigate to="/signin" replace={true} />
  );
};
