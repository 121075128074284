import { Grid } from '@mui/material'
import React from 'react'
import FaqAccordian from './FaqAccordian'

export default function FaqHome() {
  return (
    <>
        <section className='py-12 px-4 bg-slate-50 faqpnl'>
            <div className='container mx-auto '>
                <h4 className='text-center font-bold mb-3 text-primary'>Get Your Answer</h4>
                <div className='flex items-center justify-center mb-16'>
                    <h2 className='font-bold lg:text-4xl pagetitle relative text-2xl text-center'>Frequently Asked Questions</h2>
                </div>
                <Grid container columns={12} spacing={3} className='justify-center mb-12'>
                    <Grid item lg={8} justifyContent="center">
                        <p className='text-center text-sm'>You have questions - we have answers. Here are some of the most common questions customers ask before selling their devices. If you can't find the answer to your question below, just reach out to our friendly support team.</p>
                    </Grid>
                </Grid>

                <FaqAccordian/>
            </div>
        </section>
    </>
  )
}
