import React from 'react';

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const WeeklyAvailability = ({availability, setAvailability}) => {


  const handleAddTimeRange = (day) => {
    setAvailability({
      ...availability,
      [day]: [...availability[day], { start: '', end: '' }],
    });
  };

  const handleRemoveTimeRange = (day, index) => {
    const updatedTimes = availability[day].filter((_, i) => i !== index);
    setAvailability({
      ...availability,
      [day]: updatedTimes,
    });
  };

  const handleTimeChange = (day, index, field, value) => {
    const updatedTimes = availability[day].map((time, i) =>
      i === index ? { ...time, [field]: value } : time
    );
    setAvailability({
      ...availability,
      [day]: updatedTimes,
    });
  };

  return (
    <div>
      {daysOfWeek.map((day) => (
        <div key={day} className="flex mb-1 bg-gray-100 py-2 px-2 md:px-3 rounded-lg">
          <label className='md:min-w-28 min-w-20 font-bold text-black text-sm'>
            <input className='mr-2'
              type="checkbox"
              checked={availability[day].length > 0}
              onChange={() =>
                setAvailability({
                  ...availability,
                  [day]: availability[day].length > 0 ? [] : [{ start: '', end: '' }],
                })
              }
            />
            {day}
          </label>
          {availability[day].length === 0 ? (
            <span className='text-xs inline-block py-1 px-3 bg-red-200 rounded text-red-700 font-semibold text-gray-500 '>Unavailable</span>
          ) : (
            <div className="">
              {availability[day].map((time, index) => (
                <div key={index} className="mb-1">
                  <input className='px-2 py-2 bg-white border rounded text-sm'
                    type="time"
                    value={time.start}
                    onChange={(e) => handleTimeChange(day, index, 'start', e.target.value)}
                  />
                  <span> - </span>
                  <input className='px-2 py-2 bg-white border rounded text-sm'
                    type="time"
                    value={time.end}
                    onChange={(e) => handleTimeChange(day, index, 'end', e.target.value)}
                  />
                  <button type='button' onClick={() => handleRemoveTimeRange(day, index)} className='h-6 text-white rounded-full w-6 bg-red-500 inline-block hover:bg-red-600 ml-1'>✕</button>
                </div>
              ))}
              <button type='button' onClick={() => handleAddTimeRange(day)} className='py-2 px-2 text-xs rounded  text-white bg-green-400 hover:bg-green-500'>+ Add More</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WeeklyAvailability;
