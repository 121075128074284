import React from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
export default function VideoConsult({ data, date, slot }) {
  const navigate = useNavigate();

  const onVideoConsultationHandler = () => {
    data.appointment_type = "Video";
    data.appointment_date = moment(date).format("DD MMM YYYY");
    data.appointment_time = slot;
    navigate("/checkout", { state: data });
  };

  return (
    <>
      <div className="rounded-xl shadow-lg bg-white overflow-hidden mb-3">
        <div className="py-3 px-5 flex justify-between bg-green-200">
          <h3 className="font-semibold text-lg">Video Consultation</h3>
          <h4 className="text-primary font-semibold">
            $ {data.Fares.online_fare} fee
          </h4>
        </div>
        <div className="py-4 px-5">
          <div className="flex gap-3 mb-2">
            <span>
              <i class="fa-solid fa-user-doctor text-cyan-400"></i>
            </span>
            <p>{data.Name}</p>
          </div>
          <div className="flex gap-3 mb-2">
            <span>
              <i class="fa-regular fa-calendar-days text-green-700"></i>
            </span>
            <p>
              {moment(date).format("DD MMM YYYY")}, {slot}
            </p>
          </div>
          <div className="mt-4">
            <button
              type="button"
              onClick={onVideoConsultationHandler}
              className="py-2 block text-center px-4 text-white bg-primary rounded-full min-w-36"
            >
              Book Video Consultation
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
