import moment from "moment";
export const DisableSlots = (aptDate, aptTime) => {
  const [startTimeStr, endTimeStr] = aptTime.split(" - ");
  const now = moment();
  const appointmentDate = moment(aptDate, "DD MMM YYYY");
  const startTime = moment(startTimeStr, "h:mm A");
  const endTime = moment(endTimeStr, "h:mm A");

  if (aptTime && appointmentDate.isSame(now, "day")) {
    if (now.isBetween(startTime, endTime)) {
      return false;
    } else if (now.isBefore(startTime)) {
      return false;
    } else {
      return true;
    }
  } else if (appointmentDate.isAfter(now, "day")) {
    return false;
  }
};
