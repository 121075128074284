import React from 'react'
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';

export default function Specialityblock({page}) {

    const {doctorspecialityList} = useSelector((state) => state.doctor)
    const CURRENT_ITEMS_PER_PAGE = page === 'home' ? 10 : doctorspecialityList.length
      const specialities = doctorspecialityList.slice(0,CURRENT_ITEMS_PER_PAGE).map((speciality,index) =>
        
            <Grid item xs={15} sm={7.5} md={5} lg={3} >
                <div key={index}>
                <Link to="/find-doctor" state={speciality} className='specialistBox'>
                    <div className='specialistBox-body w-max flex items-center justify-between'>
                        <div className='flex items-center'>
                            <div className='specialistIcon'><img src={`${Constants.getAsset}${speciality.icon}`} alt="specialityIcon" className='w-100'/></div>
                            <div>
                                <h2>{speciality.name}</h2>
                                <span>{speciality.totalDoc} Doctors</span>
                            </div>
                        </div>
                        <div>
                            <div className='speBtn'><i class="fa-solid fa-angle-right"></i></div>
                        </div>
                    </div>
                </Link>
                </div>
            </Grid>
        
      );
  return (
    <>
        <Grid container  columns={15} spacing={2} alignItems="center">
            {specialities}
        </Grid>
    </>
  )
}
