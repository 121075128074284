import moment from "moment";
export const FetchStatus = (aptDate, aptTime) => {
  const [startTimeStr, endTimeStr] = aptTime.split(" - ");
  const now = moment();
  const appointmentDate = moment(aptDate, "DD MMM YYYY");
  const startTime = moment(startTimeStr, "h:mm A");
  const endTime = moment(endTimeStr, "h:mm A");
  
  if (appointmentDate.isSame(now, 'day')) {
    if (now.isBetween(startTime, endTime)) {
      return true;
    } else if (now.isBefore(startTime)) {
      return true
    } else {
      return false
    }
  } else if (appointmentDate.isAfter(now, 'day')) {
    return true
  } else {
    return false
  }
};
