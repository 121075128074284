import React, { useState, useEffect, useRef } from "react";
import BannerDashboard from "./BannerDashboard";
import { Grid } from "@mui/material";
import ToggleNavs from "../ToggleNavs/toggleNavs";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../Constants";
import { FetchAllUpcomingAppointment } from "../../Thunk/doctorThunks";
import moment from "moment";
export default function AppointmentDoctor() {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [activeSecondTab, setActiveSecondTab] = useState("Video");
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs = useRef({});
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { doctorAppointments } = useSelector((state) => state.doctor || []);
  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const handleClickOutside = (event) => {
    if (dropdownRefs.current) {
      for (const key in dropdownRefs.current) {
        if (
          dropdownRefs.current[key] &&
          !dropdownRefs.current[key].contains(event.target)
        ) {
          setOpenDropdown(null);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(FetchAllUpcomingAppointment({ Id: user.Id }));
  }, [dispatch, user.Id]);

  // Filter appointments based on activeTab and activeSecondTab

  const UpcomingfilteredAppointments = doctorAppointments?.filter(
    (appointment) => {
      if (activeTab === "upcoming") {
        return appointment.appointment_type === activeSecondTab;
      }
      return false;
    }
  );

  const PastfilteredAppointments = doctorAppointments?.filter((appointment) => {
    if(activeTab === "past") {
      return appointment.appointment_type === activeSecondTab && moment(appointment.appointment_date, 'DD MMM YYYY').isBefore(moment())
    }
    return false
  })

  return (
    <>
      <BannerDashboard pageTitle="My Appointments" />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h2 className="text-2xl font-semibold  pb-3 mb-6 border-b">
                My Appointments
              </h2>
              <div>
                <div className="flex space-x-4 border-b">
                  <button
                    className={`py-2 px-4 ${
                      activeTab === "upcoming"
                        ? "text-primary border-b-2 border-blue-700 font-semibold"
                        : "text-gray-500 hover:text-black font-semibold"
                    }`}
                    onClick={() => setActiveTab("upcoming")}
                  >
                    Upcoming
                  </button>
                  <button
                    className={`py-2 px-4 ${
                      activeTab === "past"
                        ? "text-primary border-b-2 border-blue-700 font-semibold"
                        : "text-gray-500 hover:text-black font-semibold"
                    }`}
                    onClick={() => setActiveTab("past")}
                  >
                    Past
                  </button>
                </div>
              </div>

              {activeTab === "upcoming" && (
                <>
                  <div className="TabContainer">
                    <div
                      onClick={() => setActiveSecondTab("Video")}
                      className={`TabBlock ${
                        activeSecondTab === "Video" && "ActiveTabBlock"
                      }`}
                    >
                      <h5
                        className={
                          activeSecondTab === "Video"
                            ? "ActiveTabText"
                            : "TabText"
                        }
                      >
                        Video Meets
                      </h5>
                    </div>

                    <div
                      className={`TabBlock ${
                        activeSecondTab === "Clinic" && "ActiveTabBlock"
                      }`}
                      onClick={() => setActiveSecondTab("Clinic")}
                    >
                      <h5
                        className={
                          activeSecondTab === "Clinic"
                            ? "ActiveTabText"
                            : "TabText"
                        }
                      >
                        Clinic Visits
                      </h5>
                    </div>
                  </div>
                  <div className="mt-4">
                    {UpcomingfilteredAppointments?.length > 0 ? (
                      <Grid container columns={12} spacing={2}>
                        {UpcomingfilteredAppointments.map((itx, index) => (
                          <Grid key={index} item lg={4} md={6} sm={12}>
                            <div className="shadow hover:shadow-xl transition-all rounded-lg bg-white border-l-[3px] border-blue-700">
                              <div className="flex justify-between p-3">
                                <div className="flex">
                                  <div className="mr-2">
                                    <img
                                      src={`${Constants.getImg}${itx.patient_pic}`}
                                      className="w-10 h-10 min-h-10 rounded-full object-cover"
                                      alt={itx.patient_name}
                                    />
                                  </div>
                                  <div>
                                    <h2 className="text-base font-semibold">
                                      {itx.patient_name}
                                    </h2>
                                    <p className="text-xs text-gray-500">
                                      {itx.patient_gender}{" "}
                                      {itx.patient_medical_history.Age} years
                                    </p>
                                  </div>
                                </div>
                                <div className="relative">
                                  <div
                                    className="relative inline-block text-left"
                                    ref={(el) =>
                                      (dropdownRefs.current[index] = el)
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="border-0 p-2"
                                      id="menu-button"
                                      aria-expanded="true"
                                      aria-haspopup="true"
                                      onClick={() => toggleDropdown(index)}
                                    >
                                      <i className="fa-regular fa-ellipsis-vertical"></i>
                                    </button>
                                    {openDropdown === index && (
                                      <div
                                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="menu-button"
                                        tabIndex="-1"
                                      >
                                        <div className="py-1" role="none">
                                          <a
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700"
                                          >
                                            Details
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {itx.appointment_type === "Video" && (
                                <div className="w-full flex justify-end">
                                  <button className="bg-blue-700 text-white text-sm px-3 py-1 rounded-lg my-1 mx-1">
                                    Join
                                  </button>
                                </div>
                              )}
                              <div className="p-3 border-t">
                                <p className="text-sm text-gray-600">
                                  <i className="fa-solid fa-calendar mr-1"></i>
                                  {itx.appointment_date} {itx.appointment_time}
                                </p>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <div className="flex w-full h-full justify-center mt-5">
                        <p className="font-semibold">
                          No Upcoming Appointments
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
              {activeTab === "past" && (
                    <>
                    <div className="TabContainer">
                      <div
                        onClick={() => setActiveSecondTab("Video")}
                        className={`TabBlock ${
                          activeSecondTab === "Video" && "ActiveTabBlock"
                        }`}
                      >
                        <h5
                          className={
                            activeSecondTab === "Video"
                              ? "ActiveTabText"
                              : "TabText"
                          }
                        >
                          Video Meets
                        </h5>
                      </div>
  
                      <div
                        className={`TabBlock ${
                          activeSecondTab === "Clinic" && "ActiveTabBlock"
                        }`}
                        onClick={() => setActiveSecondTab("Clinic")}
                      >
                        <h5
                          className={
                            activeSecondTab === "Clinic"
                              ? "ActiveTabText"
                              : "TabText"
                          }
                        >
                          Clinic Visits
                        </h5>
                      </div>
                    </div>
                    <div className="mt-4">
                      {PastfilteredAppointments?.length > 0 ? (
                        <Grid container columns={12} spacing={2}>
                          {PastfilteredAppointments.map((itx, index) => (
                            <Grid key={index} item lg={4} md={6} sm={12}>
                              <div className="shadow hover:shadow-xl transition-all rounded-lg bg-white border-l-[3px] border-blue-700">
                                <div className="flex justify-between p-3">
                                  <div className="flex">
                                    <div className="mr-2">
                                      <img
                                        src={`${Constants.getImg}${itx.patient_pic}`}
                                        className="w-10 h-10 min-h-10 rounded-full object-cover"
                                        alt={itx.patient_name}
                                      />
                                    </div>
                                    <div>
                                      <h2 className="text-base font-semibold">
                                        {itx.patient_name}
                                      </h2>
                                      <p className="text-xs text-gray-500">
                                        {itx.patient_gender}{" "}
                                        {itx.patient_medical_history.Age} years
                                      </p>
                                    </div>
                                  </div>
                                  <div className="relative">
                                    <div
                                      className="relative inline-block text-left"
                                      ref={(el) =>
                                        (dropdownRefs.current[index] = el)
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="border-0 p-2"
                                        id="menu-button"
                                        aria-expanded="true"
                                        aria-haspopup="true"
                                        onClick={() => toggleDropdown(index)}
                                      >
                                        <i className="fa-regular fa-ellipsis-vertical"></i>
                                      </button>
                                      {openDropdown === index && (
                                        <div
                                          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="menu-button"
                                          tabIndex="-1"
                                        >
                                          <div className="py-1" role="none">
                                            <a
                                              href="#"
                                              className="block px-4 py-2 text-sm text-gray-700"
                                            >
                                              Details
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {itx.appointment_type === "Video" && (
                                  <div className="w-full flex justify-end">
                                    <button className="bg-blue-700 text-white text-sm px-3 py-1 rounded-lg my-1 mx-1">
                                      Join
                                    </button>
                                  </div>
                                )}
                                <div className="p-3 border-t">
                                  <p className="text-sm text-gray-600">
                                    <i className="fa-solid fa-calendar mr-1"></i>
                                    {itx.appointment_date} {itx.appointment_time}
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <div className="flex w-full h-full justify-center mt-5">
                          <p className="font-semibold">
                            No Past Appointments
                          </p>
                        </div>
                      )}
                    </div>
                  </>
              )}
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
