import React, { useState } from 'react'

export default function Chatbot() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const closeVisiblity = () => {
    setIsVisible(false);
  }
  return (
    <>
        <button className='fixed bg-primary w-12 h-12 z-10 bottom-[20px] right-[15px] text-white text-center text-2xl rounded-full leading-[48px] shadow-lg' onClick={toggleVisibility}>
        <i className="fa-regular fa-comment-dots"></i>
        </button>

        {isVisible && (
        <div className='rounded-xl  overflow-hidden shadow-2xl fixed sm:bottom-[20px] bottom-[75px] sm:right-[70px] right-[15px] sm:w-[350px] w-[300px] z-10'>
          <div className='flex justify-between bg-primary px-3 py-3 text-white items-center'>
            <div className='flex items-center'>
              <div className='w-9 h-9 bg-white text-primary font-bold text-center text-2xl leading-[36px] rounded-full mr-2'>D</div>
              <div>
                <h3 className='font-bold text-base'>Hi! This is Mia</h3>
                <p className='text-xs'>How can I help you today?</p>
              </div>
            </div>
            <div className='pl-3'>
              <button className='text-2xl' onClick={closeVisiblity}><i class="fa-regular fa-xmark"></i></button>
            </div>
          </div>

          <div className='p-3 bg-white sm:h-[350px] h-[350px] overflow-y-auto'>
            <div className='text-center mb-3'>
              <div className='inline-block py-1 px-3 rounded-full min-w-[120px] bg-gray-100 text-xs'>Today</div>
            </div>

            <div className='flex items-end mb-3 gap-2'>
              <div className='w-8 h-8 min-w-8 bg-primary text-white font-semibold text-center text-base leading-[32px] rounded-full '>D</div>
              <div className='text-sm bg-gray-200 p-3 rounded-xl mb-1'>
                <div >
                  <p className='mb-4'>👋 Welcome, my name is Mia, CallSalute's  new generative AI powered bot!</p>
                  <p className='mb-4'> 🤖 I'm here to assist you with any questions you have about Ago Sibillo and our products. From understanding our cutting-edge AI technologies to booking a demo, I've got you covered! </p>
                  <p>Just ask away! Let's get started and make your experience with Ago Sibillo a great one! How can I assist you today?</p>
                </div>
                <p className='text-xs text-gray-500 text-right mt-3'>10:25 PM</p>
              </div>
            </div>
            <div className='flex items-end mb-3 gap-2 justify-end'>
              <div className='w-8 h-8 min-w-8 bg-black text-white font-semibold text-center text-base leading-[32px] rounded-full order-2'>J</div>
              <div className='order-1 text-sm bg-violet-100 p-3 rounded-xl'>
                <div>
                  <p>👋 My Name is John </p>
                </div>
                <p className='text-xs text-gray-500 text-right mt-3'>10:26 PM</p>
              </div>
            </div>
            
          </div>

          <div className='p-3 bg-white border-t'>
            <form>
              <div className='flex justify-between gap-1'>
                <div className=' grow '>
                  <input type='text' placeholder='Type your Message' className='rounded-full h-10 px-3 text-sm border border-gray-300 w-full'/>
                </div>
                <div className='flex items-center gap-1'>
                  <label className='inline-block h-10 w-10 rounded-full text-primary leading-[40px] text-center bg-violet-200'>
                    <input type='file' className='hidden'/>
                    <i class="fa-regular fa-paperclip"></i>
                  </label>
                  <button className='h-10 w-10 rounded-full text-white leading-[40px] text-center bg-primary'><i class="fa-sharp fa-solid fa-paper-plane"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
        )}
    </>
  )
}
