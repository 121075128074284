import React from "react";
import { useSelector } from "react-redux";
import { CalculateAge } from "../../Utils/AgeCalculator";
export default function SidebarNav({
  LogoutHandler,
  coverPic,
  profilePic,
  onProfileChange,
  onCoverChange,
}) {
  const { user } = useSelector((state) => state.auth);
  const { patientData } = useSelector((state) => state.user);
  return (
    <>
      <div className="dashboardNav shadow-lg bg-white">
        <div className="relative usercover">
          <img src={coverPic} className="h-50 object-cover w-full" />
          <label className="cameraCover">
            <input
              type="file"
              className="hidden"
              onChange={(e) => onCoverChange(e)}
            />{" "}
            <i class="fa-solid fa-camera"></i>
          </label>
        </div>
        <div className="mb-5">
          <div className="profileImg relative">
            <img src={profilePic} />
            <label className="cameraCover">
              <input
                type="file"
                className="hidden"
                onChange={(e) => onProfileChange(e)}
              />{" "}
              <i class="fa-solid fa-camera"></i>
            </label>
          </div>

          <h2 className="text-center font-semibold mt-2 mb-1 text-xl">
            {user && user.Name}
          </h2>
          <h3 className="text-center text-md">Patient ID : {user.PatientId} </h3>
          <h3 className="text-center text-md">
            {user && user.gender}
            {patientData.DOB
              ? ", " + CalculateAge(patientData.DOB)
              : ""}
          </h3>
        </div>
        <div className="border-t-2 border-slate-200 mb-4">
          <a
            href="/dashboard"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Dashboard
          </a>
          <a
            href="/my-appointments"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            My Appointments
          </a>
          <a
            href="/my-medical-record"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            My Medical Records
          </a>
          <a
            href="/my-favourite"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Favourites
          </a>
          <a
            href="/transaction"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Transactions and Payments
          </a>
          <a
            href="/notification"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Notification Preferences
          </a>
          <a
            href="/change-password"
            className="block py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
          >
            {" "}
            Change Password
          </a>
          <a
            href="#"
            className="block text-primary py-3 px-4 border-b-2 border-slate-200 hover:bg-blue-700 hover:text-white transition-all delay-500 hover:pl-5"
            onClick={() => LogoutHandler()}
          >
            {" "}
            Logout
          </a>
        </div>
      </div>
    </>
  );
}
