import { Grid } from '@mui/material'
import React from 'react'
import ServicesCard from './ServicesCard'
import AccordianStep from './AccordianStep'
import MedicalHealthBlock from './MedicalHealthBlock'
import DownloadApp from './DownloadApp'
import Memberslide from './Memberslide'
import Blogcard from './Blogcard'

export default function Whoweare() {
  return (
    <>
    <section className='border-t-2'>
        <div className='container mx-auto px-4'>
            <Grid container columns={12} spacing={5} className='items-center'>
                <Grid item lg={6} md={6} sm={12}>
                    <img src='assets/images/whowe.png' className='w-full'/>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                    <div className='pt-10'>
                        <h1 className='font-bold lg:text-4xl relative text-2xl mb-12'>We Are Always Ensure Best Medical Treatment For Your Health</h1>
                        <p className='mb-7'>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies.</p>
                        <p className='mb-7'>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies.</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    </section>
    <section className='py-14'>
        <div className='container mx-auto px-4'>
            <h2 className='font-bold lg:text-4xl relative text-2xl mb-20 text-center middleBorder'>We Have 25 Years Experience In Medical<br/> Health Services</h2>
            <ServicesCard/>
        </div>
    </section>
    <section className='pb-14 pt-5'>
        <div className='container mx-auto px-4'>
            <h2 className='font-bold lg:text-4xl relative text-2xl mb-20 text-center'>Put 20+ years of virtual care expertise to work for you</h2>
            <Grid container columns={12} spacing={4}>
                <Grid item lg={6} md={12}>
                    <h3 className='font-bold lg:text-4xl relative text-2xl mb-6'>Caring for you every step of the way Caring</h3>
                    <p className='mb-10'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum</p>
                    <AccordianStep/>
                </Grid>
                <Grid item lg={6} md={12} >
                    <img src='assets/images/expert-work.png'/>
                </Grid>
            </Grid>
        </div>
    </section>
    <MedicalHealthBlock/>
    <DownloadApp/>
    <section className='pb-14 pt-5'>
        <div className='container mx-auto px-4'>
            <h2 className='font-semibold lg:text-4xl relative text-2xl mb-10 text-center'>Trusted by 100+ U.S. health plans and their members</h2>
            <Memberslide/>
        </div>
    </section>
    <section className='pb-14 pt-5'>
        <div className='container mx-auto px-4'>
            <h2 className='font-semibold lg:text-4xl relative text-2xl mb-10 text-center middleBorder'>See Our Latest Blog</h2>
            <Blogcard/>
        </div>
    </section>
    </>
  )
}
