import React, {useEffect, useState} from 'react'
import BannerDashboard from './BannerDashboard'
import { Grid } from '@mui/material'
import ToggleNavs from '../ToggleNavs/toggleNavs'
import { useDispatch, useSelector } from 'react-redux'
import DrCard from '../Specialistdr/DrCard'
import { GetAllDoctorLists } from '../../Thunk/doctorThunks'

export default function Myfavourite() {
    const {user} = useSelector((state) => state.auth);
    const { doctorLists } = useSelector((state) => state.doctor);
    const [selectedHeart, setSelectedHeart] = useState(
        (user && user.Favourites) || []
      );
  
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetAllDoctorLists())
      },[])

    const filteredDoctors = doctorLists.filter((doctor) => selectedHeart.includes(doctor._id));
  return (
    <>
        <BannerDashboard pageTitle="My Favourite"/>
        <section className="py-8 bg-slate-50">
            <div className="container mx-auto px-4">
                <Grid container columns={12} spacing={4}>
                    <Grid item xs={12} md={4} lg={3}>
                       <ToggleNavs />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <DrCard doctorLists={filteredDoctors} name="favourites" selectedHeart={selectedHeart} setSelectedHeart={setSelectedHeart}/>
                    </Grid>
                </Grid>
            </div>
        </section>
    </>
  )
}
