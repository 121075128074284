import moment from "moment";
const dayMap = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

const formatTime = (time) => {
  return moment(time, "HH:mm").format("h:mm A");
};

export const parseApiData = (apiData) => {
  const weeklyHours = apiData.WeeklyHours;
  const dateSpecificHours = apiData.DateSpecificHours || [];
  const availableDates = [];
  Object.keys(weeklyHours).forEach((day) => {
    if (weeklyHours[day].length > 0) {
      const dayIndex = dayMap[day];
      if (dayIndex !== undefined) {
        const now = new Date();
        for (let i = 0; i < 30; i++) {
          const date = new Date(now);
          date.setDate(now.getDate() + i);

          if (date.getDay() === dayIndex) {
            availableDates.push({
              date,
              slots: weeklyHours[day].map(
                ({ start, end }) => `${formatTime(start)} - ${formatTime(end)}`
              ),
            });
          }
        }
      }
    }
  });

  dateSpecificHours.forEach((element) => {
    element.date.forEach((timestamp) => {
      const date = new Date(timestamp);
      availableDates.push({
        date,
        slots: element.timeRanges.map(
          ({ start, end }) => `${formatTime(start)} - ${formatTime(end)}`
        ),
      });
    });
  });

  return availableDates;
};
