import {
  ADD_BLOG_COMMENT,
  CLEAR_MSG,
  CREATE_BLOG_POSTS,
  DELETE_BLOG_POSTS,
  FETCH_BLOG_BYBLOGID,
  FETCH_BLOG_BYID,
  FETCH_BLOG_POSTS,
  LIKE_BLOG,
  UNLIKE_BLOG,
} from "../Actions/Blogs/types";

const initialState = {
  blogList: [],
  blogPerUser: {},
  doctorBlogList: [],
  msg: null,
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOG_POSTS:
      return {
        ...state,
        blogList: action.payload,
      };
    case CREATE_BLOG_POSTS:
      return {
        ...state,
        msg: action.payload.message,
      };
    case FETCH_BLOG_BYID:
      return {
        ...state,
        doctorBlogList: action.payload,
      };
    case FETCH_BLOG_BYBLOGID:
      return {
        ...state,
        blogPerUser: action.payload,
      };
    case ADD_BLOG_COMMENT:
      return {
        ...state,
        msg: action.payload.message,
      };
    case LIKE_BLOG:
      return {
        ...state,
        msg: action.payload.message,
      };
    case UNLIKE_BLOG:
      return {
        ...state,
        msg: action.payload.message,
      };
    case DELETE_BLOG_POSTS:
      return {
        ...state,
        msg: action.payload.message,
      };
    case CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };
    default:
      return state;
  }
};

export default blogReducer;
