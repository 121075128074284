import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Aboutus() {
  const products = [
    {title: 'T-shirt', subtitle:'Polo Tshirt', proImg:'https://i.imgur.com/1bX5QH6.jpg'},
    {title: 'Jeans', subtitle:'ABC Jeans', proImg:'https://i.imgur.com/1bX5QH6.jpg' },
    {title: 'Shirt', subtitle:'Raymond', proImg:'https://i.imgur.com/1bX5QH6.jpg'},
    {title: 'Sharee', subtitle:'MInu', proImg:'https://i.imgur.com/1bX5QH6.jpg'},
  ]
  const listItems = products.map(product =>
    <div className='col-lg-3' key={product.id}>
      <div className='card shadow-lg'>
        <div className='card-body'>
          <img src={product.proImg} className='w-100'/>
          <h2 className='h4 fw-bold'>{product.title}</h2>
          <span className='badge bg-secondary'>{product.subtitle}</span>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Breadcumb pagetitle="About Us"/>
      <section className='py-5'>
        <div className='container'>
          <div className='row g-3'>
            {listItems}
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4 mb-3">
              <div className="card aboutcard border-0 bg-light shadow h-100">
                <div className="card-body p-4">
                  <h5 className="fw-bold mb-4">Education</h5>
                  <img src="assets/images/client-1.png" width="120" className="mb-2"/>
                  <p>Bachelor of Arts, Florida International University, Miami, Florida</p>
                  <img src="assets/images/client-2.png" width="120" className="mb-2"/>
                  <p>Juris Doctorate, University of Miami School of Law, Coral Gables, Florida</p>
                  <img src="assets/images/client-3.png" width="120" className="mb-2"/>
                  <p>Master of Business Administration, Florida State University, Tallahassee, Florida</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-3">
              <div className="card aboutcard border-0 bg-light shadow h-100">
                <div className="card-body p-4">
                  <h5 className="fw-bold mb-4">Military schools</h5>
                  <ul>
                    <li>U.S. Marine Corps Command & Staff College</li>
                    <li>National Defense University's Joint and Combined Warfighting School</li>
                    <li>U.S. Air Force Air War College (TLS)</li>
                    <li>U.S. Army Ethics Counselor's Course</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-3">
              <div className="card aboutcard border-0 bg-light shadow h-100">
                <div className="card-body p-4">
                  <h5 className="fw-bold mb-4">Admissions and Certifications</h5>
                  <ul>
                    <li>State Bar of California</li>
                    <li>State Bar of Florida</li>
                    <li>U.S. Military Courts.</li>
                    <li>Certified Professional Contracts Manger - National Contracts Manager Association</li>
                    <li>Lean Green Belt Certification, British Quality Foundation</li>
                    <li>Top Secret/SCI Security Clearance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
