import { Grid } from "@mui/material";
const HeartRateMeasurement = ({
  register,
  children,
  disableCondition = { disableCondition },
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <label className="text-sm mb-2 block">
          Heart Rate Measurement Reading
        </label>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          {...register("hr_reading")}
          disabled={disableCondition}
        />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
export default HeartRateMeasurement;
