const AccordionHeader = ({ fieldName, disabledFields, register }) => {
    return (
        <div class="flex">
        <div class="flex items-center me-4">
          <input
            type="radio"
            value="yes"
            {...register(fieldName)}
            disabled={disabledFields}
          />
          <label for="inline-radio" class="ms-2 text-sm font-medium">
            yes
          </label>
        </div>
        <div class="flex items-center me-4">
          <input
            type="radio"
            value="no"
            {...register(fieldName)}
            disabled={disabledFields}
          />
          <label
            for="inline-2-radio"
            class="ms-2 text-sm font-medium"
          >
            no
          </label>
        </div>
      </div>
    )
}
export default AccordionHeader;